import './App.css'
import { Routes, Route } from 'react-router-dom'
import { SharedLayout } from '../src/components/SharedLayout/SharedLayout'
import { routes } from './routes/routes'
import useAuth from 'hooks/useAuth'
import { Login } from 'pages/Login/Login'
// import { Spinner } from "./components/Loading/Loading";

const Authenticated = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<SharedLayout />}>
          <>
            {routes.map((route, index) => {
              return <Route path={route.path} element={<route.Component />} key={index} />
            })}
          </>
        </Route>
      </Routes>
    </>
  )
}

const App = () => {
  const auth = useAuth()

  return <>{auth.isAuthenticated ? <Authenticated /> : <Login />}</>
}

export default App
