import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 650px;
  height: 540px;
  border-radius: 25px;
  padding: 1.6rem 1.4rem;
  display: flex;
  flex-direction: column;
  .clickBtn {
    cursor: pointer;
  }
`
