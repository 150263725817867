import React, { useRef, useState } from 'react'
import { SelectPeriod, SelectPeriodMine } from './ChoseCompany.styled'
import { Target } from 'assets/icons/target'
import { Chevron } from 'assets/images/icons'
import { LIST_COMPANIES } from '../../graphql'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useQuery } from '@apollo/client'
import useFirstCompany from 'hooks/useFirstCompany'
import { ICompanyForSelect } from 'types/companies.interface'
import { Flex } from 'styling/GlobalStyles'

export const CshoseCompany = ({
  handleCompanyChange,
  handleDateChange,
}: {
  handleCompanyChange?: any
  handleDateChange?: any
}) => {
  const { data } = useQuery(LIST_COMPANIES, {
    variables: {
      dateFrom: '2023-05-10',
      dateTo: '2023-05-10',
    }
  })
  const [open, setOpen] = useState<boolean>(false)

  const [date, setDate] = useState(new Date())
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { selectedCompany } = useSelector((state: RootState) => state.companies)
  const handleDropDownFocus = (state: boolean) => {
    setOpen(!state)
  }

  useFirstCompany()

  const handleSelect = (company) => {
    handleCompanyChange && handleCompanyChange({ name: company.name, id: company.id })
  }

  const onChange = (dt, name) => {
    setDate(dt)
    handleDateChange(dt, name)
  }
  return (
    <>
      <SelectPeriodMine
        tabIndex={1}
        onBlur={() => setOpen(false)}
        ref={dropdownRef}
        onClick={() => handleDropDownFocus(open)}
      >
        <Flex gap='14px' center>
          <Target></Target>
          <SelectPeriod>
            <span>Select mine</span>
            <p>{selectedCompany?.name}</p>
          </SelectPeriod>
        </Flex>
        <Chevron />
        {open && (
          <ul>
            {data?.listCompanies.map((company: ICompanyForSelect) => (
              <li onClick={() => handleSelect(company)} key={company.id}>
                {company.name}
              </li>
            ))}
          </ul>
        )}
      </SelectPeriodMine>
    </>
  )
}
