import React from 'react'
import { BigPageTitle } from '../../styling/GlobalStyles'
import { DashboardContainer } from './SettingsTitle.styled'

export const SettingsTitle = () => {
  return (
    <>
      <DashboardContainer>
        <BigPageTitle>Settings</BigPageTitle>
      </DashboardContainer>
    </>
  )
}
