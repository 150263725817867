import React from 'react'

export const ClipBoard = () => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.3251 4H18.3381C18.872 4 19.384 4.21071 19.7616 4.58579C20.1391 4.96086 20.3511 5.46957 20.3511 6V20C20.3511 20.5304 20.1391 21.0391 19.7616 21.4142C19.384 21.7893 18.872 22 18.3381 22H6.26011C5.72622 22 5.21421 21.7893 4.8367 21.4142C4.45918 21.0391 4.2471 20.5304 4.2471 20V6C4.2471 5.46957 4.45918 4.96086 4.8367 4.58579C5.21421 4.21071 5.72622 4 6.26011 4H8.27311'
      //   stroke="#FF9900"
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.3187 2H9.27964C8.72376 2 8.27313 2.44772 8.27313 3V5C8.27313 5.55228 8.72376 6 9.27964 6H15.3187C15.8745 6 16.3252 5.55228 16.3252 5V3C16.3252 2.44772 15.8745 2 15.3187 2Z'
      //   stroke="#FF9900"
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
