export const GalleryAdd = () => (
  <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.7474 15.2292C10.5941 15.2292 8.85156 13.4867 8.85156 11.3333C8.85156 9.18 10.5941 7.4375 12.7474 7.4375C14.9007 7.4375 16.6432 9.18 16.6432 11.3333C16.6432 13.4867 14.9007 15.2292 12.7474 15.2292ZM12.7474 9.5625C11.7699 9.5625 10.9766 10.3558 10.9766 11.3333C10.9766 12.3108 11.7699 13.1042 12.7474 13.1042C13.7249 13.1042 14.5182 12.3108 14.5182 11.3333C14.5182 10.3558 13.7249 9.5625 12.7474 9.5625Z'
      fill='#FF9900'
    />
    <path
      d='M21.2526 32.2291H12.7526C5.0601 32.2291 1.77344 28.9424 1.77344 21.2499V12.7499C1.77344 5.05742 5.0601 1.77075 12.7526 1.77075H18.4193C19.0001 1.77075 19.4818 2.25242 19.4818 2.83325C19.4818 3.41409 19.0001 3.89575 18.4193 3.89575H12.7526C6.22177 3.89575 3.89844 6.21909 3.89844 12.7499V21.2499C3.89844 27.7808 6.22177 30.1041 12.7526 30.1041H21.2526C27.7834 30.1041 30.1068 27.7808 30.1068 21.2499V14.1666C30.1068 13.5858 30.5884 13.1041 31.1693 13.1041C31.7501 13.1041 32.2318 13.5858 32.2318 14.1666V21.2499C32.2318 28.9424 28.9451 32.2291 21.2526 32.2291Z'
      fill='#FF9900'
    />
    <path
      d='M30.1042 8.14575H22.3125C21.7317 8.14575 21.25 7.66409 21.25 7.08325C21.25 6.50242 21.7317 6.02075 22.3125 6.02075H30.1042C30.685 6.02075 31.1667 6.50242 31.1667 7.08325C31.1667 7.66409 30.685 8.14575 30.1042 8.14575Z'
      fill='#FF9900'
    />
    <path
      d='M26.2109 12.0417C25.6301 12.0417 25.1484 11.56 25.1484 10.9792V3.1875C25.1484 2.60667 25.6301 2.125 26.2109 2.125C26.7918 2.125 27.2734 2.60667 27.2734 3.1875V10.9792C27.2734 11.56 26.7918 12.0417 26.2109 12.0417Z'
      fill='#FF9900'
    />
    <path
      d='M3.78544 27.9063C3.44544 27.9063 3.10544 27.7363 2.9071 27.4388C2.58127 26.9571 2.70877 26.2913 3.19044 25.9654L10.1746 21.2763C11.7046 20.2563 13.8154 20.3696 15.2037 21.5454L15.6712 21.9563C16.3796 22.5654 17.5837 22.5654 18.2779 21.9563L24.1712 16.8988C25.6871 15.6096 28.0387 15.6096 29.5546 16.8988L31.8637 18.8821C32.3029 19.2646 32.3596 19.9304 31.9771 20.3838C31.5946 20.8229 30.9287 20.8796 30.4754 20.4971L28.1662 18.5138C27.4579 17.9046 26.2537 17.9046 25.5596 18.5138L19.6662 23.5713C18.1646 24.8604 15.7987 24.8604 14.2829 23.5713L13.8154 23.1604C13.1638 22.6079 12.0871 22.5513 11.3646 23.0471L4.3946 27.7363C4.19627 27.8496 3.98377 27.9063 3.78544 27.9063Z'
      fill='#FF9900'
    />
  </svg>
)
