import styled from 'styled-components'

export const DashboardMain = styled.div`
  width: 100%;
  display: flex;
  /* margin-top: 1.75rem; */
  justify-content: space-between;
  @media (max-width: 940px) {
    flex-direction: column;
  }
`
export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`
// export const LeftSideDashboard = styled.div`
//   width: 65%;
//   display: flex;
//   flex-direction: column;
//   /* max-width: 56.25rem; */
// `
// export const RightSideDashboard = styled.div`
//   width: 33%;
//   display: flex;
//   flex-direction: column;
//   border: 1px solid red;
//   /* max-width: 56.25rem; */
// `

// export const Processed = styled.div`
//   display: flex;
//   flex-direction: column;
//   /* max-width: 56.188rem; */
//   width: 100%;
//   padding: 1.3rem;
//   background: #ffffff;
//   box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
//   border-radius: 0.75rem;

// `

// export const OnProcessed = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `
// // export const AvgGradePlace = styled.div`
// //   display: flex;
// //   justify-content: space-between;
// //   align-items: center;
// //   width: 25%;

// // `
// export const AvgGrade = styled.div`
//   display: flex;
//   background: ${(props) => props.theme.colorWhite};
//   border: 1px solid #e8e8e8;
//   border-radius: 8.33482px;
//   /* width: 210px; */
//   /* padding: 14px 17px; */
//   flex-direction: column;
//   width: 25%;
// `
// export const AvgGradeTitle = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 1px solid ${(props) => props.theme.grayChart};
//   padding-bottom: 7px;
//   p {
//     margin: 0;
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 21px;
//     text-transform: uppercase;
//     color: #464255;
//   }
// `
// export const EnvironmentalCards = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
// `
// export const EnvironmentalLeft = styled.div`
//   display: flex;
//   width: calc(50% - 1rem);
//   justify-content: space-between;
//   flex-direction: column;
//   gap: 1rem;
// `
// export const EnvironmentalRight = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 48%;
//   justify-content: space-between;
//   gap: 1rem;
//   /* max-width: 27rem; */
// `
// export const EnvironmentalItems = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   ${(props) => props.column && 'flex-direction: column'};
//   ${(props) => props.center && 'align-items:  center'};
//   ${(props) => props.between && 'justify-content: space-between'};
//   ${(props) => props.justcenter && 'justify-content: center'};
//   ${(props) => props.start && 'align-items:  flex-start'};
//   background: ${(props) => props.theme.colorWhite};
//   box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
//   border-radius: 0.875rem;
//   padding: 1rem 0.93rem;
// `
// export const EnvironmentalItemsDouble = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   flex-direction: column;
//   background: ${(props) => props.theme.colorWhite};
//   box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
//   border-radius: 0.875rem;
//   padding: 1rem 0.93rem;
// `
