import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto 1.375rem auto;
  @media (max-width: 515px) {
    text-align: center;
    justify-content: center;
  }
`

export const DashboardContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 3rem;
    text-transform: capitalize;
    color: ${(props) => props.theme.colorOrange};
    margin: 0;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.375rem;
    letter-spacing: -0.02em;
    font-feature-settings: 'calt' off;
    color: ${(props) => props.theme.colorGray};
  }
`
