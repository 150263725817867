import { Dashboard } from '../pages/Dashboard/Dashboard'
import { Licenses } from '../pages/Licenses/Licenses'
import { Certificate } from '../pages/Certificate/Certificate'
import { Employees } from '../pages/Employees/Employees'
import { Management } from '../pages/Management/Management'

import { WaterBodies } from '../pages/WaterBodies/WaterBodies'
import { CsrAllocationsPage } from '../pages/CsrAllocations/CsrAllocationsPage'
import { Jobs } from '../pages/Jobs/Jobs'
import { Settings } from '../pages/SettingsPage/Settings'
import { Environmental } from 'pages/Environmental/Environmental'
import { Reclamation } from 'pages/Reclamation/Reclamation'

export const routes = [
  {
    path: '/',
    Component: Dashboard,
  },
  {
    path: '/licenses',
    Component: Licenses,
  },
  {
    path: '/certificates',
    Component: Certificate,
  },
  {
    path: '/employees',
    Component: Employees,
  },
  {
    path: '/employees/management',
    Component: Management,
  },

  {
    path: '/waterbodies',
    Component: WaterBodies,
  },
  {
    path: '/education',
    Component: CsrAllocationsPage,
  },
  {
    path: '/medical',
    Component: CsrAllocationsPage,
  },
  {
    path: '/infrastructure',
    Component: CsrAllocationsPage,
  },
  {
    path: '/community',
    Component: CsrAllocationsPage,
  },
  {
    path: '/water',
    Component: CsrAllocationsPage,
  },
  {
    path: '/environmental',
    Component: Environmental,
  },
  {
    path: '/reclamation',
    Component: Reclamation,
  },

  // {
  //   path: '/modal',
  //   Component: ModalPicture,
  // },

  {
    path: '/jobs',
    Component: Jobs,
  },
  {
    path: '/settings',
    Component: Settings,
  },
]
