import { createSlice } from '@reduxjs/toolkit'
import { ICompany } from 'types/companies.interface'

const initialState: {selectedCompany:ICompany} = {
  selectedCompany: null,
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setSelectedCompany: (state, { payload }) => {
      state.selectedCompany = payload
    },
  },

  extraReducers: {},
})

export const { setSelectedCompany } = companiesSlice.actions
export default companiesSlice.reducer
