import styled from 'styled-components'

export const RightPlaceDashboard = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  @media (max-width: 940px) {
    width: 100%;
    margin-top: 20px;
  }
  /* max-width: 56.25rem; */
`
interface EnvironmentalItemsProps {
  column?: string
  center?: string
  between?: string
  justcenter?: string
  start?: string
}
export const EnvironmentalItems = styled.div<EnvironmentalItemsProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.column && 'flex-direction: column'};
  ${(props) => props.center && 'align-items:  center'};
  ${(props) => props.between && 'justify-content: space-between'};
  ${(props) => props.justcenter && 'justify-content: center'};
  ${(props) => props.start && 'align-items:  flex-start'};
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  padding: 1.125rem 0.93rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-0.3rem);
  }
`
