import styled from 'styled-components'

export const LeftSideDashboardContainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 940px) {
    width: 100%;
  }
`
export const RightSideDashboard = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  @media (max-width: 940px) {
    width: 100%;
  }
`

export const Processed = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.3rem;
  background: #ffffff;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
`
export const OnProcessed = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  @media (max-width: 450px) {
    div {
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
`
export const AllAvgGrade = styled.div`
  display: flex;
  background: ${(props) => props.theme.colorWhite};
  width: 100%;
  gap: 1rem;
  @media (max-width: 690px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const AvgGrade = styled.div`
  display: flex;
  background: ${(props) => props.theme.colorWhite};
  border: 1px solid #e8e8e8;
  border-radius: 8.33482px;
  flex-direction: column;
  width: 25%;
  @media (max-width: 690px) {
    width: calc(50% - 1rem);
  }
  @media (max-width: 450px) {
    width: 85%;
  }
`
export const AvgGradeTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.grayChart};
  padding-bottom: 7px;
  p {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #464255;
  }
`
export const EnvironmentalCards = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 690px) {
    flex-direction: column;
    gap: 1rem;
  }
`
export const EnvironmentalLeft = styled.div`
  display: flex;
  width: calc(50% - 1rem);
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 690px) {
    width: 100%;
  }
`
export const EnvironmentalRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 690px) {
    width: 100%;
  }
`
interface EnvironmentalItemsProps {
  column?: string
  center?: string
  between?: string
  justcenter?: string
  start?: string
}
export const EnvironmentalItems = styled.div<EnvironmentalItemsProps>`
  height: 78px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.column && 'flex-direction: column'};
  ${(props) => props.center && 'align-items:  center'};
  ${(props) => props.between && 'justify-content: space-between'};
  ${(props) => props.justcenter && 'justify-content: center'};
  ${(props) => props.start && 'align-items:  flex-start'};
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  padding: 1rem 0.93rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-0.3rem);
  }
`
export const EnvironmentalItemsDouble = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  padding: 1rem 0.93rem;
`
export const WaterClean = styled.div`
  position: relative;
  width: 168px;
  height: 20px;
  border-radius: 0.9rem;
  background: linear-gradient(
    90deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(17, 93, 231, 1) 49%,
    rgba(237, 40, 40, 1) 100%
  );
`
export const WaterLineSpace = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 20px;
`
