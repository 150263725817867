import gql from 'graphql-tag'

import {
  COMPANY_FIELDS_FRAGMENT,
  COMPANY_ID_FIELDS_FRAGMENT,
  EMPLOYEE_FIELDS_FRAGMENT,
  ORE_PROCESSED_FIELDS_FRAGMENT,
} from './fragments'

export const GET_ME = gql`
  query getMe {
    getMe {
      id
      firstName
      lastName
      email
      phoneNumber
      userType
    }
  }
`

export const LIST_COMPANIES = gql`
  ${COMPANY_ID_FIELDS_FRAGMENT}
  query listCompanies {
    listCompanies {
      ...CompanyIdFields
      name
    }
  }
`

export const GET_COMPANY_BY_ID = gql`
  ${COMPANY_FIELDS_FRAGMENT}
  ${EMPLOYEE_FIELDS_FRAGMENT}
  ${ORE_PROCESSED_FIELDS_FRAGMENT}
  query getCompanyById($companyId: String!, $dateFrom: String!, $dateTo: String!) {
    getCompanyById(id: $companyId, dateFrom:$dateFrom, dateTo:$dateTo) {
      ...CompanyFields
      employees {
        ...EmployeeFields
      }
      oreProcessed {
        ...OreProcessedFields
      }
      csrAllocationSum{
        type
        investmentAmount
      }
      totalFuelLeakage
      reclamationSum{
        reclaimedAreaSize
        unreclaimedAreaSize
      }
      latestPondStatus{
        id
        waterLevel
      }
      latestWaterBodies{
        id
        title
        status
        updatedAt
      }
      
    }
  }
`

export const LIST_EMPLOYEES_BY_COMPANY_ID = gql`
  ${EMPLOYEE_FIELDS_FRAGMENT}
  query listEmployeesByCompanyId($companyId: String!) {
    listEmployeesByCompanyId(companyId: $companyId) {
      lastName
      createdAt
      id
      ...EmployeeFields
    }
  }
`
export const GET_LOCATIONS_BY_COMPANY_ID = gql`
  query listAllocationsByCompanyId($companyId: String!, $dateTo: String!, $dateFrom: String!) {
    listAllocationsByCompanyId(companyId: $companyId, dateFrom: $dateFrom, dateTo:$dateTo) {
      title
      type
      createdAt
      investmentAmount
      images {
        image {
          key
        }
        caption
        location {
          lat
          lng
        }
      }
    }
  }
`
export const GET_LIST_RECLAMATIONS_BY_COMPANY_ID = gql`
  query listReclamationsByCompanyId($companyId: String!) {
    listReclamationsByCompanyId(companyId: $companyId) {
      title
      id
      images {
        image {
          key
        }
        caption
        location {
          lat
          lng
        }
      }
      coordinates {
        lat
        lng
      }
      reclaimedArea {
        lat
        lng
      }
      reclaimed
      createdAt
    }
  }
`
// export const GET_POND_STATUS_BY_COMPANY_ID = gql`
//   query listPondStatusByCompanyId($companyId: String!) {
//     listPondStatusByCompanyId(companyId: $companyId) {
//       waterLevel
//       createdAt
//   }
// `
// export const GET_FUEL_LEAKAGE_BY_COMPANY_ID = gql`
//   query listFuelLeakageByCompanyId($companyId: String!) {
//     listfuelLeakageByCompanyId(companyId: $companyId) {
//       waterLevel
//       createdAt
//   }
// `
export const GET_WATER_BODIES_BY_COMPANY_ID = gql`
  query listWaterBodiesByCompanyId($companyId: String!) {
    listWaterBodiesByCompanyId(companyId: $companyId) {
      id
      title
      images {
        image {
          key
        }
        caption
        location {
          lat
          lng
        }
      }
      coordinates {
        lat
        lng
      }
      status
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
        email
        phoneNumber
        userType
      }
    }
  }
`
