import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`
export const EmployesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
  align-items: center;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`
export const EmployesCard = styled.div`
  width: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.375rem;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  cursor: pointer;
  @media (max-width: 950px) {
    width: 100%;
  }
`

export const ManagementCards = styled.div`
  display: flex;
  width: 100%;
  /* margin-top: 1.5rem; */
  /* padding-top: 1.37rem; */
  padding-bottom: 1.37rem;
  gap: 1rem;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
`
export const ManagementOneCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  padding: 1.375rem;
  width: calc(50% - 0.5rem);
  @media (max-width: 700px) {
    width: 100%;
  }
`
