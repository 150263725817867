import React from 'react'
import styled from 'styled-components'
// import PropTypes from 'prop-types'

interface LoadingSpinnerStyledProps {
  loading?: boolean | string
  fullscreen?: boolean | string
}

const LoadingSpinnerStyled = styled.div<LoadingSpinnerStyledProps>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${(props) => (props.loading ? 'rgba(255, 255, 255, .7)' : 'rgba(255, 255, 255, 0)')};
  display: ${(props) => (props.loading ? 'flex' : 'flex')};

  opacity: ${(props) => (props.loading ? '1' : '0')};
  visibility: ${(props) => (props.loading ? 'visible' : 'hidden')};
  align-items: center;
  justify-content: center;
  z-index: ${(props) => (props.fullscreen ? '1000' : '')};
  transition: all 0.2s ease;

  @keyframes loading-spinner {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .loading-spinner div {
    position: absolute;
    width: 4rem;
    height: 4rem;
    border: 5px solid ${(props) => props.theme.colorOrange};
    border-top-color: transparent;
    border-radius: 50%;
  }
  .loading-spinner div {
    animation: loading-spinner 0.8928571428571428s linear infinite;
    top: 3.1rem;
    left: 3.1rem;
  }
  .loading-spinner-rolling {
    width: 6.25rem;
    height: 6.25rem;
    display: inline-block;
    overflow: hidden;
  }
  .loading-spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
  .loading-spinner div {
    box-sizing: content-box;
  }
`

export const Spinner = ({ fullscreen = true, loading = false }) => {
  return (
    <LoadingSpinnerStyled fullscreen={fullscreen} loading={loading ? 'true' : ''}>
      <div className='loading-spinner-rolling'>
        <div className='loading-spinner'>
          <div></div>
        </div>
      </div>
    </LoadingSpinnerStyled>
  )
}
// Spinner.propTypes = {
//   fullscreen: PropTypes.bool,
//   loading: PropTypes.bool,
// }
