import { useQuery } from '@apollo/client'
import { isEmpty } from '@aws-amplify/core'
import { LeftSideDashboard } from 'components/LeftSideDashboard/LeftSideDashboard'
import { RightSideDashboard } from 'components/RightSideDashboard/RightSideDashboard'
import { setSelectedCompany } from 'features/companies/companiesSlice'
import { LIST_COMPANIES } from 'graphql/queries'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { DashboardTitle } from '../../components/DashboardTitle/DashboardTitle'
import { NavBar } from '../../components/NavBar/NavBar'
import { GET_COMPANY_BY_ID } from '../../graphql'
import { DashboardMain, DashboardWrapper } from './Dashboard.styled'
import { Spinner } from 'components/Loading/Loading'
import { endOfWeek, startOfWeek } from 'date-fns'

export const Dashboard = () => {
  const [dateFrom, setDateFrom] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [dateTo, setDateTo] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }))
  const { selectedCompany } = useSelector((state: RootState) => state.companies)
  const listData = useQuery(LIST_COMPANIES)
  const { loading, error, data, refetch } = useQuery(GET_COMPANY_BY_ID, {
    variables: {
      dateFrom,
      dateTo,
      companyId: selectedCompany?.id
        ? selectedCompany.id
        : !isEmpty(listData.data)
          ? listData.data.listCompanies[0].id
          : '',
    },
  })
  const dispatch = useDispatch()

  useEffect(() => {
    data && dispatch(setSelectedCompany(data.getCompanyById))
  }, [data])

  if (error) {
    console.log('error', error)
    return <p>Error :( </p>
  }

  const handleCompanyChange = (company) => {
    refetch({ companyId: company.id })
  }

  useEffect(() => {
    if (selectedCompany) {
      refetch()
    }
  }, [dateFrom, dateTo])

  // const { name } = data?.getCompanyById
  return (
    <>
      <DashboardWrapper>
        <NavBar handleCompanyChange={handleCompanyChange} />
        <DashboardTitle
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          companyName={selectedCompany?.name} />
        {selectedCompany?.id && (
          <DashboardMain>
            <LeftSideDashboard company={selectedCompany} />
            <RightSideDashboard company={selectedCompany} dateTo={dateTo} dateFrom={dateFrom} />
          </DashboardMain>
        )}
        <Spinner loading={loading || listData.loading} />
      </DashboardWrapper>
    </>
  )
}
