import styled from 'styled-components'

export const EmptyListComponent = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
`
export const EmptyListPlace = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`
export const EmptyListImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`
export const EmptyListText = styled.div`
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
  color: black;
  width: 100%;
`
