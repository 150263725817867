import React, { useRef } from 'react'
import { AnimationModal } from '../../styling/GlobalStyles'
import { ModalContainer, ModalWrapper } from './Modal.styled'
import { useOnClickOutside } from 'utils/helpers'

interface ModalProps {
  black?: boolean
  noMaxH?: boolean
  children: any
  maxWidth?: boolean
  noBack?: boolean
  close?: any
  modal?: any
}

export const Modal = ({ black, children, maxWidth, noBack, close, modal }: ModalProps) => {
  const ref = useRef(null)
  useOnClickOutside(ref, () => modal && close(false))

  return (
    <ModalWrapper
      id='modalWrapper'
      ref={ref}
      onClick={(ev) => {
        if (ev.target.id === 'modalWrapper') {
          close((prev: any) => ({ ...prev, open: false }))
        }
      }}
      noBack={noBack}
      black={black}
    >
      <AnimationModal>
        <ModalContainer maxWidth={maxWidth} width={'100%'}>
          {children}
        </ModalContainer>
      </AnimationModal>
    </ModalWrapper>
  )
}
