import React from 'react'
import {
  DashboardContainer,
  DashboardContainerLeft,
  DashboardContainerRight,
} from './DashboardTitle.styled'
import { BigPageTitle } from '../../styling/GlobalStyles'
import { PeriodMine } from '../PeriodMine/PeriodMine'

export const DashboardTitle = ({ companyName, dateFrom, dateTo, setDateFrom, setDateTo }) => {
  return (
    <>
      <DashboardContainer>
        <DashboardContainerLeft>
          <BigPageTitle>Dashboard</BigPageTitle>
          <p>{companyName}</p>
        </DashboardContainerLeft>
        <DashboardContainerRight>
          <PeriodMine
            dateFrom={dateFrom}
            dateTo={dateTo}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
          />
        </DashboardContainerRight>
      </DashboardContainer>
    </>
  )
}
