import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Amplify, Auth } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
})

const authLink = setContext(async () => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()
  return {
    headers: { Authorization: token },
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: from([authLink, new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })]),
})

export default client
