import React, { useState } from 'react'
import {
  DashboardContainer,
  DashboardContainerLeft,
  DashboardContainerRight,
} from './LicensesTitle.styled'
import { BigPageTitle, Tabs, TabsContainer } from '../../styling/GlobalStyles'
// import { ArrowRight } from '../../assets/icons/ArrowRight'

export const LicensesTitle = () => {
  const [activeTab, setActiveTav] = useState(1)

  return (
    <>
      <>
        <DashboardContainer>
          <DashboardContainerLeft>
            <BigPageTitle>Licenses</BigPageTitle>
            <p>BSD Mining Service</p>
          </DashboardContainerLeft>
          <DashboardContainerRight>
            <TabsContainer>
              <Tabs active={activeTab === 1} onClick={() => setActiveTav(1)}>
                Licenses
              </Tabs>
              <Tabs active={activeTab === 2} onClick={() => setActiveTav(2)}>
                Mining Licenses
              </Tabs>
            </TabsContainer>
          </DashboardContainerRight>
        </DashboardContainer>
      </>
    </>
  )
}
