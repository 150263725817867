import React, { useState } from 'react'
import { ManagementTitle } from '../../components/ManagementTitle/ManagementTitle'
import { NavBar } from '../../components/NavBar/NavBar'
import { Flex, P2, P3, PageFooter } from '../../styling/GlobalStyles'
import { DashboardContainer, ManagementCards, ManagementOneCard } from './Management.styed'
import { Pagination } from '../../components/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'

export const Management = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  return (
    <>
      <DashboardContainer>
        {/* <NavBar /> */}
        <ManagementTitle />
        <ManagementCards>
          <ManagementOneCard>
            <Flex column gap='0.375rem'>
              <P2 bold>Yehuda Arie Leib Ingber</P2>
              <P3>Ghana</P3>
            </Flex>
            <Flex>
              <P3>Director</P3>
            </Flex>
          </ManagementOneCard>
          <ManagementOneCard>
            <Flex column gap='0.375rem'>
              <P2 bold>Yehuda Arie Leib Ingber</P2>
              <P3>Ghana</P3>
            </Flex>
            <Flex>
              <P3>Director</P3>
            </Flex>
          </ManagementOneCard>
        </ManagementCards>
        <PageFooter>
          {/* {Math.ceil(operations.totalCount / 30) > 1 && ( */}
          <Pagination
            setPage={(selected) => navigate('?page=' + selected + '&limit=30')}
            // pageCount={Math.ceil(operations.totalCount / 30)}
            forcePage={page - 1}
            pageCount={5}
          />
          {/* )} */}
        </PageFooter>
      </DashboardContainer>
    </>
  )
}
