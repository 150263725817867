import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto 1.375rem auto;
  @media (max-width: 515px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
  }
  @media (max-width: 515px) {
    align-items: center;
  }
`

export const DashboardContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 3rem;
    text-transform: capitalize;
    color: ${(props) => props.theme.colorOrange};
    margin: 0;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.375rem;
    letter-spacing: -0.02em;
    font-feature-settings: 'calt' off;
    color: ${(props) => props.theme.colorGray};
  }
  @media (max-width: 515px) {
    text-align: center;
  }
`
export const DashboardContainerRight = styled.div`
  display: flex;
  gap: 1.25rem;
  @media (max-width: 600px) {
    /* width: 100%; */
    justify-content: space-between;
  }
  @media (max-width: 515px) {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
`
export const SelectPeriodMine = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 14.37rem;
  height: 3.7rem;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0.6rem 1.5rem 3.375rem rgba(7, 6, 61, 0.05);
  border-radius: 0.625rem;
  gap: 1.25rem;
  @media (max-width: 600px) {
    width: 100%;
  }
`
export const SelectPeriod = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9rem;
    letter-spacing: -0.02em;
    font-feature-settings: 'calt' off;
    color: ${(props) => props.theme.colorGray};
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.3rem;
    color: ${(props) => props.theme.colorGray};
  }
`
