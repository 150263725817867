import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Line } from '../../assets/icons/line'
import { ArrowLeft } from '../../assets/icons/arrowLeft'
import { Flex, H1, IconButtonNeutral, P2 } from '../../styling/GlobalStyles'
import { PeriodMine } from '../PeriodMine/PeriodMine'
import { DashboardContainer } from './WaterBodies.styled'

export const WaterBodiesTitle = () => {
  const navigate = useNavigate()
  return (
    <>
      <DashboardContainer>
        <Flex between width='100%'>
          <Flex gap='1.2rem' center justcenter>
            <IconButtonNeutral onClick={() => navigate(-1)}>
              <ArrowLeft />
            </IconButtonNeutral>
            <Line />
            <Flex column justcenter>
              <H1 orange>Water Bodies</H1>
              <P2>Octobar 2022</P2>
            </Flex>
          </Flex>
        </Flex>
      </DashboardContainer>
    </>
  )
}
