export const Chevron = () => (
  <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.08936 1.00195L6.07493 5.98753L11.0605 1.00195'
      stroke='#667080'
      strokeWidth='1.66782'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
