import React, { useEffect, useState } from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { ReclamationTitle } from '../../components/ReclamationTitle/ReclamationTitle'
import { DashboardWrapper } from './Reclamation.styled'
import GoogleMapReact from 'google-map-react'
import { GET_LIST_RECLAMATIONS_BY_COMPANY_ID, GET_COMPANY_BY_ID } from '../../graphql'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { setSelectedCompany } from 'features/companies/companiesSlice'
import { Spinner } from 'components/Loading/Loading'
import { getCenter } from 'utils/helpers'

export const Reclamation = () => {
  const { selectedCompany } = useSelector((state: RootState) => state.companies)
  const companyById = useQuery(GET_COMPANY_BY_ID, { skip: true })
  const { loading, data } = useQuery(GET_LIST_RECLAMATIONS_BY_COMPANY_ID, {
    variables: { companyId: selectedCompany ? selectedCompany.id : '' },
  })

  const [googleMap, setMap] = useState<any>()
  const [polygons, setPolygons] = useState<any>([])
  const dispatch = useDispatch<AppDispatch>()

  console.log(data)

  const onMapLoaded = (map: any) => {
    const leftButton = document.createElement('div')
    // MapButton(leftButton, () => setIsMapStreched((p) => !p));
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(leftButton)

    setMap(map)
  }
  useEffect(() => {
    polygons?.forEach((p: any) => p.setMap(null))
    setPolygons([])
    if (googleMap) {
      const newPolygons = data?.listReclamationsByCompanyId?.map((area) => createPolygon(area))
      setPolygons(newPolygons)
    }
  }, [data, googleMap])

  console.log(data)

  useEffect(() => {
    if (googleMap && data?.listReclamationsByCompanyId[0]?.reclaimedArea) {
      googleMap.setZoom(17)
      googleMap.setCenter(getCenter(data?.listReclamationsByCompanyId[0]?.reclaimedArea ?? []))
    }
  }, [data])

  const createPolygon = (area: any) => {
    const polygon = new window.google.maps.Polygon({
      paths: area?.reclaimedArea?.map((r: any) => new window.google.maps.LatLng(r.lat, r.lng)),
      strokeColor: '#4d4c4c',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: area.reclaimed ? '#ff9900' : '#3BB871',
      fillOpacity: 0.35,
      // regionName: eta.regionName,
      map: googleMap,
      // geofenceId: eta.geofenceId,
    })
    // window.google.maps.event.addListener(polygon, 'mouseover', () => {
    //   setRegionName(polygon.regionName);
    //   setShowTooltip(true);
    //   polygon.setOptions({ fillOpacity: 0.6 });
    // });

    // window.google.maps.event.addListener(polygon, 'mouseout', () => {
    //   setShowTooltip(false);
    //   polygon.setOptions({ fillOpacity: 0.35 });
    // });

    // window.google.maps.event.addListener(polygon, 'click', () => {
    //   if (selectedEta?.geofenceId === polygon.geofenceId) {
    //     setSelectedEta(null);
    //   } else {
    //     setSelectedEta(
    //       etas.filter((x) => x.geofenceId === polygon.geofenceId)[0]
    //     );
    //   }
    // }
    // );
    return polygon
  }

  const handleCompanyChange = async (company) => {
    const comp = await companyById.refetch({ companyId: company.id })
    dispatch(setSelectedCompany(comp.data.getCompanyById))
  }
  return (
    <>
      <DashboardWrapper>
        <NavBar handleCompanyChange={handleCompanyChange} />
        <ReclamationTitle />
        <div
          style={{
            width: '100%',
            position: 'relative',
            borderRadius: '12px',
            overflow: 'hidden',
            marginTop: '1rem',
          }}
        >
          <GoogleMapReact
            style={{
              width: '100%',
              height: '75vh',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            bootstrapURLKeys={{
              key: 'AIzaSyDBluVqssEGhqrn5u6QiqFBu-oEXUIOkDI',
            }}
            defaultCenter={{ lat: 0, lng: 0 }}
            defaultZoom={2}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => onMapLoaded(map)}
            options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
          >
            {/* {Object.values(tasksMarkers).map((marker, i) => {
              if (marker.length === 1) {
                return ( */}

            {/* );
              }
            })} */}
          </GoogleMapReact>
        </div>
        <Spinner loading={loading || companyById.loading || !selectedCompany.id} />
      </DashboardWrapper>
    </>
  )
}
