import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto 1.375rem auto;
  @media (max-width: 590px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const DashboardContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 3rem;
    text-transform: capitalize;
    color: ${(props) => props.theme.colorOrange};
    margin: 0;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.375rem;
    letter-spacing: -0.02em;
    font-feature-settings: 'calt' off;
    color: ${(props) => props.theme.colorGray};
  }
  @media (max-width: 590px) {
    width: 100%;
    align-items: center;
  }
`
export const DashboardContainerRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 590px) {
    width: 100%;
  }
`
