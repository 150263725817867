import gql from 'graphql-tag'

export const COMPANY_ID_FIELDS_FRAGMENT = gql`
  fragment CompanyIdFields on Company {
    __typename
    id
  }
`

export const COMPANY_FIELDS_FRAGMENT = gql`
  fragment CompanyFields on Company {
    __typename
    id
    name
    address {
      houseNumber
      streetAddress
      country
      zipCode
      city
    }
    csrAllocationSum{
      type
      investmentAmount
    }
    phoneNumber
    email
    website
    mineType
    createdAt
    updatedAt
  }
`

export const EMPLOYEE_FIELDS_FRAGMENT = gql`
  fragment EmployeeFields on Employee {
    __typename
    firstName
    lastName
    countryOfOrigin
    jobTitle
    createdAt
    updatedAt
  }
`

export const CSR_ALLOCATION_FIELDS_FRAGMENT = gql`
  fragment CsrAllocationFields on CsrAllocation {
    __typename
    title
    investmentAmount
    type
    createdAt
    updatedAt
    images {
      caption
      image {
        key
      }
    }
  }
`

export const ORE_PROCESSED_FIELDS_FRAGMENT = gql`
  fragment OreProcessedFields on OreProcessed {
    __typename
    ozWorth
    osAmount
  }
`
export const ENVIRONMENTAL_FIELDS_FRAGMENT = gql`
  fragment EnvironmentalFields on Environmental {
    __typename
    waterBodies {
      __typename
      title
      status
      updatedAt
    }
    pondStatus {
      waterLevel
      updatedAt
    }
    fuelLeakage {
      leakage
      createdAt
    }
    machineLocation {
      coordinates {
        lat
        lng
      }
      machineId
      machineType
      createdAt
    }
    reclamation {
      reclaimedArea {
        lat
        lng
      }
      id
      title
      images {
        caption
        location {
          lat
          lng
        }
      }
      coordinates {
        lat
        lng
      }
      reclaimed
    }
  }
`
