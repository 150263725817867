import React, { useState } from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Flex, H1, P3, Separator, IconButtonNeutral, P2 } from '../../styling/GlobalStyles'
import { CsrAllocationTitleBar, DashboardWrapper } from './CsrAllocationsPage.styled'
import { SliderSwiper } from '../../components/SwiperSlider/SliderSwiper'
import { GET_LOCATIONS_BY_COMPANY_ID } from '../../graphql'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeft } from '../../assets/icons/arrowLeft'
import { PeriodMine } from '../../components/PeriodMine/PeriodMine'
import { Line } from '../../assets/icons/line'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedCompany } from 'features/companies/companiesSlice'
import { Emptylist } from 'components/EmptyList/Emptylist'
import { Spinner } from 'components/Loading/Loading'
import { months } from 'utils/helpers'

export const CsrAllocationsPage = () => {
  const navigate = useNavigate()
  const { selectedCompany } = useSelector((state: RootState) => state.companies)
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const [dateFrom, setDateFrom] = useState(new Date(params.get('dateFrom')))
  const [dateTo, setDateTo] = useState(new Date(params.get('dateTo')))

  const { loading, data, refetch } = useQuery(GET_LOCATIONS_BY_COMPANY_ID, {
    variables: { companyId: selectedCompany.id, dateFrom, dateTo },
  })

  const forRender = data?.listAllocationsByCompanyId?.filter(
    (l) => l.type === location.pathname.substring(1),
  )
  const getTitle = (str) => {
    const convertedStr = str.slice(1).toLowerCase()
    return convertedStr.charAt(0).toUpperCase() + convertedStr.slice(1)
  }

  // useEffect(()=>{
  //   dispatch(setSelectedCompany(d))
  // },[data])

  const handleCompanyChange = (company) => {
    refetch({ companyId: company.id })
    dispatch(setSelectedCompany(company))
  }

  return (
    <>
      <DashboardWrapper>
        <NavBar handleCompanyChange={handleCompanyChange} />
        <CsrAllocationTitleBar>
          <Flex between width='100%' center>
            <Flex gap='1.2rem' center justcenter>
              <IconButtonNeutral onClick={() => navigate(-1)}>
                <ArrowLeft />
              </IconButtonNeutral>
              <Line />
              <Flex column justcenter>
                <H1 orange>{getTitle(location.pathname)}</H1>
                <P2>{months[new Date().getMonth()] + ' ' + new Date().getFullYear()}</P2>
              </Flex>
            </Flex>
            <PeriodMine setDateFrom={setDateFrom} setDateTo={setDateTo} dateFrom={dateFrom} dateTo={dateTo} />
          </Flex>
        </CsrAllocationTitleBar>

        <Separator margin='1.5rem 0 2.5rem 0' />
        {forRender?.length > 0 ? (
          forRender?.map((l) => (
            <>
              <Flex between center mb='16'>
                <Flex>
                  <H1>{l.title}</H1>
                </Flex>
                <Flex center gap='0.2rem'>
                  <P3>Total Cost:</P3>
                  <H1 orange>GH₵ {l.investmentAmount}</H1>
                </Flex>
              </Flex>
              <SliderSwiper
                slides={l?.images?.map((i) => ({
                  image: i.image.key,
                  date: new Date(),
                  imageHeadline: i.image.caption,
                }))}
              />
              <Separator margin='1.5rem 0 2.5rem 0' />
            </>
          ))
        ) : (
          <Emptylist />
        )}
        <Spinner loading={loading} />
      </DashboardWrapper>
    </>
  )
}
