import { useState } from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { Flex, P3 } from '../../styling/GlobalStyles'
import { GallerySlider, TimePicture, SwiperWrapper, ImgPLaceContainer } from './SliderSwiper.styled'
import { ModalPicture } from '../ModalPicture/ModalPicture'
import { Modal } from '../Modal/Modal'
import NoImg from '../../assets/images/no_img.png'
import { format } from 'date-fns'
export const SliderSwiper = ({ slides }) => {
  const [modal, setModal] = useState({
    open: false,
    image: '',
  })

  // const handleImage = (link) => {
  //   link ? 'https://media.gov.dev.honestdig.io/' + link : NoImg
  // }

  // const slides = [
  //   {
  //     image:
  //       'https://www.lepotaizdravlje.rs/wp-content/uploads/2020/07/Afrika-pejzaz-putovanja-priroda-Getty.jpg',
  //     date: '10.10.2021 | 12:24',
  //     imageHeadline: 'Image Headline',
  //   },
  //   {
  //     image:
  //       'https://www.lepotaizdravlje.rs/wp-content/uploads/2020/07/Afrika-pejzaz-putovanja-priroda-Getty.jpg',
  //     date: '10.10.2021 | 12:24',
  //     imageHeadline: 'Image Headline',
  //   },
  //   {
  //     image:
  //       'https://www.lepotaizdravlje.rs/wp-content/uploads/2020/07/Afrika-pejzaz-putovanja-priroda-Getty.jpg',
  //     date: '10.10.2021 | 12:24',
  //     imageHeadline: 'Image Headline',
  //   },
  //   {
  //     image:
  //       'https://www.lepotaizdravlje.rs/wp-content/uploads/2020/07/Afrika-pejzaz-putovanja-priroda-Getty.jpg',
  //     date: '10.10.2021 | 12:24',
  //     imageHeadline: 'Image Headline',
  //   },
  //   {
  //     image:
  //       'https://www.lepotaizdravlje.rs/wp-content/uploads/2020/07/Afrika-pejzaz-putovanja-priroda-Getty.jpg',
  //     date: '10.10.2021 | 12:24',
  //     imageHeadline: 'Image Headline',
  //   },
  // ]
  return (
    <>
      <Flex>
        <SwiperWrapper>
          <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={4}
            navigation
            scrollbar={{ draggable: true }}
          >
            {slides?.map(({ image, date, imageHeadline, status }, i) => (
              <SwiperSlide key={i}>
                {/* <Flex minWidth='320px' column center gap='0.8rem' width='100%'> */}
                <ImgPLaceContainer>
                  <GallerySlider
                    image={image ? process.env.REACT_APP_ASSET_URL + image : NoImg}
                    onClick={() =>
                      setModal({
                        open: true,
                        image: image ? process.env.REACT_APP_ASSET_URL + image : NoImg,
                      })
                    }
                  >
                    <TimePicture>
                      <P3>{format(date, 'dd.MM.yyyy | HH:mm')}</P3>
                    </TimePicture>
                  </GallerySlider>
                  <P3>{imageHeadline}</P3>
                </ImgPLaceContainer>
                {/* </Flex> */}
                {/* <Flex>
                </Flex> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
      </Flex>

      {modal.open && (
        <Modal close={setModal} modal={modal.open} black>
          <ModalPicture close={setModal} image={modal.image} />
        </Modal>
      )}
    </>
  )
}
