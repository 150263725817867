import styled from 'styled-components'

export const WaterodiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* border: 1px solid red; */
  /* max-width: 1400px; */
  width: 100%;
  margin: 0 auto 1.375rem auto;
`
export const WatherBodiedsMainPlace = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
`
export const WatherBodiedsMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 323px;
`
export const WatherBodiedsMainTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`
export const WatherBodiedsMainImgPlace = styled.div`
  width: 100%;
  max-width: 323px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 4px;
`
export const WatherBodiedsImgTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WatherBodiedsImgText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WatherBodiedsImgStatus = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
