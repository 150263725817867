import React from 'react'
import { PaginationWrapper } from './Pagination.styled'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import { PaginationPrevious } from '../../assets/icons/PaginationPrevious'
import { PaginationNext } from '../../assets/icons/PaginationNext'

type TPagination = {
  pageCount: number
  setPage: (arg0: number) => any
  forcePage: number
}
export const Pagination = ({ pageCount, setPage, forcePage }: TPagination) => {
  return (
    <PaginationWrapper>
      <ReactPaginate
        onPageChange={({ selected }) => setPage(selected + 1)}
        previousLabel={PaginationPrevious}
        nextLabel={PaginationNext}
        pageCount={pageCount || 4}
        containerClassName='pagination'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        previousClassName='arrow-page'
        previousLinkClassName='page-link'
        nextClassName='arrow-page'
        nextLinkClassName='page-link'
        activeClassName='page-item-active'
        activeLinkClassName='page-link-active'
        breakLabel={false}
        forcePage={forcePage}
        disabledClassName='disabled'
        disabledLinkClassName='disabled'
      ></ReactPaginate>
    </PaginationWrapper>
  )
}

Pagination.propTypes = {
  pageCount: PropTypes.number,
  setPage: PropTypes.func,
  forcePage: PropTypes.number,
}
