import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  confirmModal: {
    isOpen: false,
    onSave: '',
  },
  isSidebarOpen: true,
  isLoadingAuth: false,
}

const globalSlice = createSlice({
  name: 'globalStates',
  initialState,
  reducers: {
    setConfirmModal: (state, { payload }) => {
      state.confirmModal = payload
    },
    setLoadingAuth: (state, { payload }) => {
      state.isLoadingAuth = payload
    },
    toggleSideBar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
  },
  extraReducers: {},
})

export const { setConfirmModal, setLoadingAuth, toggleSideBar } = globalSlice.actions
export default globalSlice.reducer
