import { ArrowRight } from '../../assets/icons/ArrowRight'
import { Settings } from '../../assets/icons/Settings'
import { MapPin } from '../../assets/icons/mapPin'
import { AlertTriangle } from '../../assets/icons/alertTriangle'
import { format } from 'date-fns'

import { BtnTrue } from '../../assets/icons/btnTrue'
import {
  H1,
  P2,
  IconButton,
  IconButtonMaps,
  IconButtonNeutral,
  Flex,
  Separator,
  theme,
  P1,
  IconButtonAlert,
} from '../../styling/GlobalStyles'
import {
  Processed,
  AvgGrade,
  LeftSideDashboardContainer,
  EnvironmentalLeft,
  EnvironmentalRight,
  EnvironmentalCards,
  EnvironmentalItems,
  AllAvgGrade,
} from './LeftSideDashboard.styled'
import { useNavigate } from 'react-router-dom'
import { ICompany } from 'types/companies.interface'


export const LeftSideDashboard = ({ company }: { company: ICompany }) => {
  const navigate = useNavigate()
  return (
    <>
      <LeftSideDashboardContainer>
        <Processed>
          <Separator margin='0.8rem 0 1.2rem 0' />
          <Flex gap='1rem' between>
            <AllAvgGrade>
              <AvgGrade>
                <Flex between center p='0.75rem'>
                  <p>AVG GRADE 0.2</p>
                  <IconButtonNeutral>
                    <ArrowRight />
                  </IconButtonNeutral>
                </Flex>
                <Separator margin='0' />
                <Flex center gap='1rem' p='0.75rem'>
                  <IconButton>
                    <Settings></Settings>
                  </IconButton>
                  <Flex column>
                    <P1 bold>229. oz</P1>
                    <H1 color={theme.colorOrange}>$442.29</H1>
                  </Flex>
                </Flex>
              </AvgGrade>

              <AvgGrade>
                <Flex between center p='0.75rem'>
                  <p>AVG GRADE 0.2</p>
                  <IconButtonNeutral>
                    <ArrowRight />
                  </IconButtonNeutral>
                </Flex>
                <Separator margin='0' />
                <Flex center gap='1rem' p='0.75rem'>
                  <IconButton>
                    <Settings></Settings>
                  </IconButton>
                  <Flex column>
                    <P1 bold>229. oz</P1>
                    <H1 color={theme.colorOrange}>$442.29</H1>
                  </Flex>
                </Flex>
              </AvgGrade>

              <AvgGrade>
                <Flex between center p='0.75rem'>
                  <p>AVG GRADE 0.2</p>
                  <IconButtonNeutral>
                    <ArrowRight />
                  </IconButtonNeutral>
                </Flex>
                <Separator margin='0' />
                <Flex center gap='1rem' p='0.75rem'>
                  <IconButton>
                    <Settings></Settings>
                  </IconButton>
                  <Flex column>
                    <P1 bold>229. oz</P1>
                    <H1 color={theme.colorOrange}>$442.29</H1>
                  </Flex>
                </Flex>
              </AvgGrade>

              <AvgGrade>
                <Flex between center p='0.75rem'>
                  <p>AVG GRADE 0.2</p>
                  <IconButtonNeutral>
                    <ArrowRight />
                  </IconButtonNeutral>
                </Flex>
                <Separator margin='0' />
                <Flex center gap='1rem' p='0.75rem'>
                  <IconButton>
                    <Settings></Settings>
                  </IconButton>
                  <Flex column>
                    <P1 bold>229. oz</P1>
                    <H1 color={theme.colorOrange}>$442.29</H1>
                  </Flex>
                </Flex>
              </AvgGrade>
            </AllAvgGrade>
          </Flex>
        </Processed>
        {/* environmental */}
        <Flex between mt='25'>
          <H1>Environmental</H1>
          {/* <ShowAllButton>Show All</ShowAllButton> */}
        </Flex>
        {/* Box cards */}
        <Flex mt='20'>
          <EnvironmentalCards>
            <EnvironmentalLeft>
              <EnvironmentalItems onClick={() => navigate('/environmental')}>
                <Flex column gap='0.3rem'>
                  <P1 bold>Excavators near river</P1>
                  <P2>
                    Total Excavators: <span>12</span>{' '}
                  </P2>
                </Flex>
                <Flex>
                  <IconButtonMaps>
                    <MapPin></MapPin>
                  </IconButtonMaps>
                </Flex>
              </EnvironmentalItems>
              <EnvironmentalItems>
                <Flex column gap='0.3rem'>
                  <P1 bold>Pond Status</P1>
                  <P2>
                    Water Level: <span>{Number(company?.latestPondStatus?.waterLevel).toFixed(2)}</span>
                    <P2></P2>
                  </P2>
                  <P2 red bold>
                    ALERT
                  </P2>
                </Flex>
                <Flex>
                  {Number(company?.latestPondStatus?.waterLevel) < -1 ? (
                    <IconButtonAlert>
                      <BtnTrue />
                    </IconButtonAlert>
                  ) : (
                    <IconButtonAlert>
                      <AlertTriangle></AlertTriangle>
                    </IconButtonAlert>
                  )}
                </Flex>
              </EnvironmentalItems>
              <EnvironmentalItems onClick={() => navigate('/waterbodies')}>
                <Flex column gap='0.3rem'>
                  <P1 bold>Water Bodies</P1>
                  <P2>
                    {company?.latestWaterBodies ? format(new Date(company?.latestWaterBodies.updatedAt), 'dd.MM.yyyy') : 'No Data'}
                  </P2>
                  {company?.latestWaterBodies?.status.toLowerCase() == 'clean' ? (
                    <P2 blue bold>
                      CLEAN
                    </P2>
                  ) : (
                    <P2 red bold>
                      SMOGGY
                    </P2>
                  )}
                </Flex>
                <Flex gap='1rem' height='61px' center>
                  {/* <BtnTrue /> */}
                  {company?.latestWaterBodies?.status.toLowerCase() == 'clean' ? (
                    <IconButtonAlert>
                      <BtnTrue />
                    </IconButtonAlert>
                  ) : (
                    <IconButtonAlert>
                      <AlertTriangle></AlertTriangle>
                    </IconButtonAlert>
                  )}
                </Flex>
              </EnvironmentalItems>
            </EnvironmentalLeft>
            <EnvironmentalRight>
              <EnvironmentalItems onClick={() => navigate('/reclamation')}>
                <Flex between width='100%'>
                  <Flex column gap='0.3rem'>
                    <P1 bold>Reclamation</P1>
                    <P2>Reclaimed Area: {company?.reclamationSum?.reclaimedAreaSize}</P2>
                    <P2>Unreclaimed Area: {company?.reclamationSum?.unreclaimedAreaSize}</P2>
                  </Flex>
                  <Flex gap='0.3rem'>
                    <IconButtonMaps>
                      <MapPin />
                    </IconButtonMaps>
                  </Flex>
                </Flex>
              </EnvironmentalItems>
              <EnvironmentalItems>
                <Flex column gap='0.3rem'>
                  <P1 bold>Fuel Leakage</P1>
                  <P2>
                    Leakage: <span>{Number(company?.totalFuelLeakage).toFixed(2)}</span>
                  </P2>
                </Flex>
                <Flex>
                  {Number(company?.totalFuelLeakage) >= Number(120) ? (
                    // {fuelLeakage.leakage >= 120 ? (
                    <IconButtonAlert>
                      <BtnTrue />
                    </IconButtonAlert>
                  ) : (
                    <IconButtonAlert>
                      <AlertTriangle></AlertTriangle>
                    </IconButtonAlert>
                  )}
                </Flex>
              </EnvironmentalItems>
              <EnvironmentalItems>
                <Flex column gap='0.3rem'>
                  <P1 bold>Air Quality</P1>
                  <P2>April 22, 2021 </P2>
                  <P2 red bold>
                    -
                  </P2>
                </Flex>
                <Flex gap='1rem' height='61px' center>
                  <IconButtonAlert>
                    <AlertTriangle />
                  </IconButtonAlert>
                  {/* <WaterClean>
                    <WaterLineSpace>
                      <WaterLine></WaterLine>
                    </WaterLineSpace>
                  </WaterClean> */}
                  {/* <IconButtonNeutral>
                    <ArrowRight />
                  </IconButtonNeutral> */}
                </Flex>
              </EnvironmentalItems>
            </EnvironmentalRight>
          </EnvironmentalCards>
        </Flex>
      </LeftSideDashboardContainer>
    </>
  )
}
