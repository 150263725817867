import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Flex, IconButton, IconButtonNeutral, P1, H1, P2, P3 } from '../../styling/GlobalStyles'
import {
  DashboardWrapper,
  EmployesCard,
  EmployesContainer,
  ManagementCards,
  ManagementOneCard,
} from './Employees.styled'
import { ArrowRight } from '../../assets/icons/ArrowRight'
import { Users } from '../../assets/icons/users'
import { EmployeesTitle } from '../../components/EmployeesTitle/EmployeesTitle'
import { useNavigate } from 'react-router-dom'
import { LIST_EMPLOYEES_BY_COMPANY_ID } from '../../graphql'
import { gql, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Emptylist } from 'components/EmptyList/Emptylist'
import { Spinner } from 'components/Loading/Loading'

export const Employees = () => {
  const { selectedCompany } = useSelector((state: RootState) => state.companies)
  const { loading, error, data, refetch } = useQuery(LIST_EMPLOYEES_BY_COMPANY_ID, {
    variables: { companyId: selectedCompany?.id },
  })
  console.log(data)
  const navigate = useNavigate()
  const handleCompanyChange = (company) => {
    refetch({ companyId: company.id })
  }

  return (
    <>
      <DashboardWrapper>
        <NavBar handleCompanyChange={handleCompanyChange} />
        <EmployeesTitle></EmployeesTitle>
        <EmployesContainer>
          {data?.listEmployeesByCompanyId?.length > 0 ? (
            data?.listEmployeesByCompanyId.map((e) => (
              <ManagementCards key={e.id}>
                <ManagementOneCard>
                  <Flex column gap='0.375rem'>
                    <P2 bold>
                      {e.lastName} {e.firstName}
                    </P2>
                    <P3>{e.countryOfOrigin}</P3>
                  </Flex>
                  <Flex>
                    <P3>{e.jobTitle}</P3>
                  </Flex>
                </ManagementOneCard>
              </ManagementCards>
            ))
          ) : (
            <Emptylist />
          )}

          {/* <EmployesCard onClick={() => navigate('/employees/management')}>
            <Flex gap='1.125rem' center>
              <IconButton>
                <Users />
              </IconButton>
              <Flex column>
                <P1 bold>Management</P1>
                <H1 orange>23</H1>
              </Flex>
            </Flex>
            <IconButtonNeutral>
              <ArrowRight />
            </IconButtonNeutral>
          </EmployesCard>

          <EmployesCard onClick={() => navigate('/employees/management')}>
            <Flex gap='1.125rem' center>
              <IconButton>
                <Users />
              </IconButton>
              <Flex column>
                <P1 bold>Security</P1>
                <H1 orange>42</H1>
              </Flex>
            </Flex>
            <IconButtonNeutral>
              <ArrowRight />
            </IconButtonNeutral>
          </EmployesCard>

          <EmployesCard onClick={() => navigate('/employees/management')}>
            <Flex gap='1.125rem' center>
              <IconButton>
                <Users />
              </IconButton>
              <Flex column>
                <P1 bold>Mining</P1>
                <H1 orange>135</H1>
              </Flex>
            </Flex>
            <IconButtonNeutral>
              <ArrowRight />
            </IconButtonNeutral>
          </EmployesCard> */}
        </EmployesContainer>
        <Spinner loading={loading} />
      </DashboardWrapper>
    </>
  )
}
