import React, { useRef, useState, useEffect } from 'react'
import {
  LoginWrapper,
  LoginContainer,
  LoginDivider,
  CodeGoBack,
  PhoneNumberError,
} from './Login.styled'
import { Button, Logo, MainHeadline, Separator } from '../../styling/GlobalStyles'
import { PhoneNumberField, TextField } from '@aws-amplify/ui-react'
import { ArrowLeft } from '../../assets/icons/ArrowLeftlog'
import PropTypes from 'prop-types'
import useAuth from '../../hooks/useAuth'
import { InputWrapper } from 'components/Input/Input.styled'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { Spinner } from 'components/Loading/Loading'
import { setLoadingAuth } from 'features/global/globalSlice'
import { useNavigate } from 'react-router-dom'

const PhoneNumber = ({ onSuccess }) => {
  const { login } = useAuth()
  const inputRef = useRef<HTMLInputElement>()
  const countryCodeRef = useRef<any>()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneHasErrors, setPhoneHasErrors] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    inputRef.current.focus()

    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        verify()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const verify = async () => {
    try {
      const pNumber = `${countryCodeRef?.current?.value}${inputRef?.current?.value}`
      setPhoneHasErrors(false)
      const cognitoUser = await login(pNumber)
      onSuccess(pNumber, cognitoUser)
    } catch (e) {
      setPhoneHasErrors(true)
      dispatch(setLoadingAuth(false))
    }
  }

  return (
    <>
      <div className={!phoneNumber ? 'phoneNumber-field-empty' : 'phoneNumber-field-filled'}>
        <PhoneNumberField
          ref={inputRef}
          countryCodeRef={countryCodeRef}
          defaultCountryCode='+381'
          label='Phone Number'
          countryCodeName='country_code'
          countryCodeLabel='Country Code'
          placeholder='2345678910'
          size='small'
          className='custom-phonenumberfield-class'
          errorMessage='Not a valid phone number!'
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        {phoneHasErrors && (
          <PhoneNumberError className='absolute'>Not a valid phone number!</PhoneNumberError>
        )}
      </div>

      <Button orange mt='50' onClick={verify} width='100' height='48'>
        Sign In
      </Button>
    </>
  )
}

const Verify = ({ user, onBack }) => {
  const { verifyCode } = useAuth()
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState('')
  const buttonRef = useRef(null)
  const dispatch = useDispatch()
  const otpRef = useRef(null)

  const navigate = useNavigate()

  const onInput = (e) => {
    setOtp(`${e.currentTarget.value}`)
  }

  useEffect(() => {
    otpRef.current.focus()
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        buttonRef.current.click()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const verify = async () => {
    dispatch(setLoadingAuth(true))
    try {
      const verif = await verifyCode(user, otp)
      dispatch(setLoadingAuth(false))

      if (verif) {
        navigate('/')
      }
    } catch (e) {
      dispatch(setLoadingAuth(false))
      setOtpError('Please provide valid code')
      return
    }
  }
  return (
    <>
      <CodeGoBack onClick={onBack}>{ArrowLeft}</CodeGoBack>
      <TextField
        ref={otpRef}
        label='Verification code'
        descriptiveText='Please enter the sms code you have recieved'
        onInput={onInput}
      />
      {otpError && <PhoneNumberError>{otpError}</PhoneNumberError>}
      <Button mt='50' orange ref={buttonRef} onClick={verify} width='100' height='48'>
        Sign In
      </Button>
    </>
  )
}

export const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [user, setUser] = useState()
  const authLoading = useSelector((state: RootState) => state.globals.isLoadingAuth)

  const onSuccess = (_phoneNumber, _user) => {
    setPhoneNumber(_phoneNumber)
    setUser(_user)
  }

  const onBack = () => {
    setPhoneNumber('')
  }

  return (
    <LoginWrapper>
      <LoginDivider>
        <LoginContainer>
          <Logo />
          <Spinner loading={authLoading} />
          <Separator />
          <MainHeadline w='100%' mb='2'>
            Welcome!
          </MainHeadline>
          <InputWrapper>
            {!phoneNumber ? (
              <PhoneNumber onSuccess={onSuccess} />
            ) : (
              <Verify onBack={onBack} user={user} />
            )}
          </InputWrapper>
        </LoginContainer>
      </LoginDivider>
    </LoginWrapper>
  )
}

PhoneNumber.propTypes = {
  onSuccess: PropTypes.func,
}

Verify.propTypes = {
  user: PropTypes.object,
  onBack: PropTypes.func,
}
