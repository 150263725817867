import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  /* max-width: 1400px; */
  width: 100%;
  margin: 0 auto 0.2rem auto;
`
export const GalleryAddPicture = styled.div`
  padding: 2.06rem;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 0.87rem;
  width: 100px;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-0.3rem);
  }
`
export const CompanyOptionsPlace = styled.div`
  display: flex;
  width: 100%;
  gap: 1.688rem;
`
export const CompanyOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const CompanyOptionsCard = styled.div`
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 0.86rem;
  padding: 0.68rem 237px 0.6rem 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-0.3rem);
  }
`
