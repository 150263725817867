import React from 'react'
import { EmptyListComponent, EmptyListImg, EmptyListPlace, EmptyListText } from './Emptylist.styled'
import { FolderNotchOpen } from '../../assets/icons/folderNotchOpen'

export const Emptylist = () => {
  return (
    <EmptyListComponent>
      <EmptyListPlace>
        <EmptyListImg>
          <FolderNotchOpen />
        </EmptyListImg>
        <EmptyListText>
          There is no data to display <br />
          for selected mine and date
        </EmptyListText>
      </EmptyListPlace>
    </EmptyListComponent>
  )
}
