import React, { useState } from 'react'
import { BigPageTitle, P2, Tabs } from '../../styling/GlobalStyles'
import {
  DashboardContainer,
  DashboardContainerLeft,
  EmployeesNumber,
} from './EmployeesTitle.styled'

export const EmployeesTitle = () => {
  const [activeTab, setActiveTav] = useState(1)

  return (
    <>
      <DashboardContainer>
        <DashboardContainerLeft>
          <BigPageTitle>Employees</BigPageTitle>
        </DashboardContainerLeft>
        <EmployeesNumber>
          {/* <Tabs width='200px' active={activeTab === 1} onClick={() => setActiveTav(1)}>
            <P2>Import Employees</P2>
          </Tabs> */}
        </EmployeesNumber>
      </DashboardContainer>
    </>
  )
}
