import { DatePickerInput } from 'components/DatePicker/DatePicker'
import useFirstCompany from 'hooks/useFirstCompany'
import { SelectPeriodMinePlace } from './PeriodMine.styled'

export const PeriodMine = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
}: {
  setDateFrom: any
  setDateTo: any
  dateTo: Date
  dateFrom: Date

}) => {

  useFirstCompany(dateFrom, dateTo)

  const onChange = (dt: Date, name: string) => {
    name == 'dateTo' ? setDateTo(dt) : setDateFrom(dt)
  }

  return (
    <>
      <SelectPeriodMinePlace>
        <DatePickerInput
          onChange={onChange}
          date={dateFrom}
          label='Date From'
          name='dateFrom'
        />

        <DatePickerInput
          onChange={onChange}
          date={dateTo}
          label='Date To'
          name='dateTo'
        />

      </SelectPeriodMinePlace>
    </>
  )
}
