import React from 'react'
import { BigPageTitle } from '../../styling/GlobalStyles'
import { DashboardContainer, DashboardContainerLeft } from './Certificate.styled'

export const CertificateTitle = () => {
  return (
    <>
      <DashboardContainer>
        <DashboardContainerLeft>
          <BigPageTitle>Certificates</BigPageTitle>
          <p>BSD Mining Service for Ghana</p>
        </DashboardContainerLeft>
      </DashboardContainer>
    </>
  )
}
