import styled from 'styled-components'
// import { ArrowLeft } from "../assets/icons/ArrowLeft";
import logo from '../assets/images/logolog.png'

export const DisableSelection = ` 
-webkit-touch-callout: none !important;
-webkit-user-select: none !important;
-khtml-user-select: none !important; 
-moz-user-select: none !important;
-ms-user-select: none !important; 
user-select: none !important; 
`
export const LogoStyled = styled.div`
  img {
    margin-top: 1.4rem;
    width: 7.75rem;
  }
`
export const Logo = () => {
  return (
    <LogoStyled>
      <img src={logo} alt='logo-img' />
    </LogoStyled>
  )
}
export const ProfilePicture = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  ${DisableSelection}/* border: 1.5px solid rgba(254, 254, 254, 0.3); */
`

export const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${DisableSelection};
`
export const theme = {
  colorWhite: '#ffffff',
  colorOrange: '#ff9900',
  colorOrangeHover: '#ea8e03',
  colorGray: '#464255',
  colorLightGray: '#c3c0cc',
  colorGrayHover: '#696675',
  colorPink: '#FF5B5B',
  shadowMedium: '0px 0px 30px rgba(0, 0, 0, 0.1)',
  greenChart: '#00A368',
  grayChart: '#F5F5F5',
  brownChart: '#B46F33',
  blueChart: '#30B6D1',
  redChart: '#FF0000',
  borderInputActive: '1px solid #efefef',
  shadowInputActive: '0px 0px 30px rgba(0, 0, 0, 0.1)',
  borderTabInactive: '1px solid #464255',
  lightBlue: ' #00F0FF',
}
interface GlobalFontsProps {
  color?: string
  pointer?: boolean
  center?: boolean
  flex?: boolean
  inline?: boolean
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  maxWidth?: string
  mSM?: string
  mtSM?: string
  mbSM?: string
  mlSM?: string
  mrSM?: string
  maxWidthSM?: string
}

export const GlobalFonts = styled.div<GlobalFontsProps>`
  font-weight: 900;
  font-size: 4.5rem;
  color: ${(props) => props.theme.colorGray};
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.pointer && `cursor: ${props.pointer};`}
  ${(props) => props.center && 'text-align: center;'}
  ${(props) => props.flex && 'display: flex;'}
  ${(props) => props.inline && 'display: inline;'}
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}rem;
  margin-bottom: ${(props) => props.mb}rem;
  margin-left: ${(props) => props.ml}rem;
  margin-right: ${(props) => props.mr}rem;

  max-width: ${(props) => props.maxWidth};

  @media (max-width: 800px) {
    margin: ${(props) => props.mSM};
    margin-top: ${(props) => props.mtSM}rem;
    margin-bottom: ${(props) => props.mbSM}rem;
    margin-left: ${(props) => props.mlSM}rem;
    margin-right: ${(props) => props.mrSM}rem;
    max-width: ${(props) => props.maxWidthSM};
  }
`

interface HRProps {
  mt?: string
}
export const HR = styled.hr<HRProps>`
  opacity: 0.3;
  border: 0.4px solid #46425530;
  margin-top: ${(props) => props.mt}rem;
  width: 100%;
`

export const BigPageTitle = styled.div`
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.colorOrange};
  position: relative;
  hr {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0.3;
    height: 0.1px;
  }
`

// TYPOGRAPHY

export const MainHeadline = styled(GlobalFonts)`
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  color: ${(props) => props.theme.colorOrange};
  text-transform: capitalize;
`
interface H1Props {
  orange?: boolean
}
export const H1 = styled(GlobalFonts)<H1Props>`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: ${(props) => (props.orange ? 'orange' : '')};
  button {
    margin-top: 0.3125rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-transform: capitalize;
`

export const H2 = styled(GlobalFonts)`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3rem;
  color: ${(props) => props.theme.colorGray};
`

export const H4Wrapper = styled.h4`
  color: ${(props) => props.color};
`

interface P1Props {
  bold?: boolean
  orange?: boolean
  gray?: boolean
}
export const P1 = styled(GlobalFonts)<P1Props>`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3rem;
  color: ${(props) => props.theme.colorGray};
  font-weight: ${(props) => (props.bold ? 'bold' : '')};
  color: ${(props) => (props.orange ? 'orange' : '')};
  color: ${(props) => (props.blue ? 'blue' : '')};
  color: ${(props) => (props.red ? 'red' : '')};
  color: ${(props) => (props.gray ? '#B4B9C5' : '')};
`
interface P2Props {
  red?: boolean
  blue?: boolean
  green?: boolean
  orange?: boolean
  bold?: boolean
}
export const P2 = styled(GlobalFonts)<P2Props>`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.9rem;
  color: ${(props) => props.theme.colorGray};
  color: ${(props) => (props.red ? 'red' : '')};
  color: ${(props) => (props.blue ? 'blue' : '')};
  color: ${(props) => (props.green ? 'green' : '')};
  color: ${(props) => (props.orange ? 'orange' : '')};
  font-weight: ${(props) => (props.bold ? 'bold' : '')};
`
interface P3Props {
  bold?: boolean
}
export const P3 = styled(GlobalFonts)<P3Props>`
  font-weight: 400;
  font-size: 0.68rem;
  line-height: 0.84rem;
  color: ${(props) => props.theme.colorGray};
  font-weight: ${(props) => (props.bold ? 'bold' : '')};
`

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  @media (max-width: 520px) {
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
`
interface TabsProps {
  active: boolean
  width?: any
}
export const Tabs = styled.button<TabsProps>`
  background: ${(props) => (props.active ? props.theme.colorWhite : 'none')};
  width: ${(props) => props.width};
  min-width: 7.1rem;
  cursor: pointer;
  font-size: 0.8rem;
  border: 0.8px solid #efefef;
  box-sizing: border-box;
  box-shadow: ${(props) => (props.active ? '0px 0px 1.5rem rgba(0, 0, 0, 0.1)' : '')};
  border-radius: 0.5rem;
  color: ${(props) => (props.active ? props.theme.colorOrange : props.theme.colorLightGray)};
  border: ${(props) =>
    props.active ? '1px solid #EFEFEF' : `1px solid ${props.theme.colorLightGray}`};
  /* opacity: ${(props) => (props.active ? '1' : '0.5')}; */
  font-weight: ${(props) => (props.active ? 'bold' : '')};
  height: 2.5rem;
  transition: all 0.2s;
  @media (max-width: 450px) {
    width: 85%;
  }
`
interface ButtonProps {
  m?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  width?: string
  height?: string
  pink?: boolean
  orange?: boolean
}

export const Button = styled.button<ButtonProps>`
  border: none;
  width: 11.25rem;
  height: ${(props) => (props.height ? props.height + 'px' : '2.5rem')};
  width: ${(props) => (props.width ? props.width + '%' : '11.25rem')};

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  ${(props) =>
    props.pink
      ? `background:${props.theme.colorPink}`
      : props.orange
      ? `background:${'#ff9900'}`
      : `background:${props.theme.colorGray}`
      ? `background:${props.theme.greenChart}`
      : `background:${props.theme.redChart}`};
  border-radius: 10px;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.3rem;
  cursor: pointer;
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  outline: none;
  svg {
    stroke: white;
  }
`
interface IconButtonMapsProps {
  noneHoverColor?: string
  redChart?: string
  bold?: string
}
export const IconButton = styled(Button)<IconButtonMapsProps>`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background-color: ${(props) => props.theme.grayChart};
  transition: all 0.2s ease;

  svg {
    stroke: ${({ theme }) => theme.colorOrange};
    stroke: ${(props) => props.noneHoverColor && props.noneHoverColor};
    transition: all 0.2s ease;
  }
  :hover {
    background-color: ${(props) => props.theme.colorOrange};
    background-color: ${(theme) => (theme.redChart ? 'red' : '')};
    font-weight: ${(props) => (props.bold ? 'bold' : '')};
  }
  :hover svg {
    stroke: white !important;
  }
`
export const IconButtonMaps = styled(Button)<IconButtonMapsProps>`
  width: 48px;
  height: 48px;
  padding: 0;
  background-color: ${(props) => props.theme.colorOrange};
  transition: all 0.2s ease;
  @media (max-width: 1100px) {
    width: 40px;
    height: 40px;
  }
  svg {
    stroke: ${({ theme }) => theme.colorWhite};
    stroke: ${(props) => props.noneHoverColor && props.noneHoverColor};
    transition: all 0.2s ease;
  }
  :hover {
    background-color: ${(props) => props.theme.colorOrange};
    background-color: ${(theme) => (theme.redChart ? 'red' : '')};
    font-weight: ${(props) => (props.bold ? 'bold' : '')};
  }
  :hover svg {
    stroke: white !important;
  }
`
export const IconButtonAlert = styled(Button)`
  width: 48px;
  height: 48px;
  padding: 0;
  background-color: ${(props) => props.theme.redChart};
  transition: all 0.2s ease;
  @media (max-width: 1100px) {
    width: 40px;
    height: 40px;
  }

  svg {
    /* stroke: ${({ theme }) => theme.colorOrange}; */
    transition: all 0.2s ease;
  }
  :hover {
    background-color: ${(props) => props.theme.redChart};
  }
  :hover svg {
    stroke: white !important;
  }
`
export const ShowAllButton = styled(Button)`
  width: 8.5rem;
  padding: 0.62rem 1.37rem;
  background-color: ${(props) => props.theme.colorOrange};
  svg {
    stroke: ${({ theme }) => theme.colorWhite};
  }
`
export const IconButtonNeutral = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: ${({ theme }) => theme.colorWhite};
  transition: all 0.2s ease;

  svg {
    stroke: ${({ theme }) => theme.colorGray};
    transition: all 0.2s ease;
  }
  :hover svg {
    stroke: ${({ theme }) => theme.colorOrange};
  }
`

interface SeparatorProps {
  margin?: string
  width?: string
}
export const Separator = styled.div<SeparatorProps>`
  margin: ${(props) => (props.margin ? props.margin : '1.25rem 0')};
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colorGray}30;
  flex: none;
  width: ${(props) => props.width};
`
interface FlexProps {
  between?: boolean
  justcenter?: boolean
  end?: string
  start?: string
  center?: boolean
  column?: boolean
  gap?: string
  relative?: string
  m?: string | number
  mt?: string | number
  mb?: string | number
  ml?: string | number
  mr?: string | number
  width?: string | number
  position?: string | number
  pointer?: string
  maxHeight?: string | number
  minHeight?: string | number
  minWidth?: string | number
  height?: string | number
  p?: string
  alend?: boolean
}
export const Flex = styled.div<FlexProps>`
  display: flex;
  ${(props) => props.between && 'justify-content: space-between'};
  ${(props) => props.justcenter && 'justify-content: center'};
  ${(props) => props.end && 'justify-content: end'};
  ${(props) => props.start && 'justify-content: start'};
  ${(props) => props.center && 'align-items:  center'};
  ${(props) => props.alend && 'align-items:  end'};
  ${(props) => props.column && 'flex-direction: column'};
  ${(props) => props.gap && `gap:${props.gap}`};
  ${(props) => props.relative && 'position:relative'};
  ${(props) => (props.m ? 'margin:' + props.m : '')};
  padding: ${(props) => props.p};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => props.position && 'position:' + props.position};
  ${(props) => props.pointer && 'cursor:pointer'};
  ${(props) => props.maxHeight && 'max-height:' + props.maxHeight};
  ${(props) => props.minHeight && 'min-height:' + props.minHeight};
  ${(props) => props.minWidth && 'min-width:' + props.minWidth};
  ${(props) => props.height && 'height:' + props.height};

  h1 {
    font-weight: 700;
    font-size: 3rem;
    line-height: 100%;
    color: ${(props) => props.theme.colorGray};
  }
  span {
    font-size: 0.813rem;
    font-weight: bold;
  }
`
export const AnimationModal = styled.div`
  > div:nth-of-type(1) {
    animation-name: popup;
    animation-duration: 0.3s;
  }

  @keyframes popup {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`
export const PageFooter = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: calc(15rem + 2rem);
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: flex-end;
`
export const InvalidText = styled.p`
  color: crimson;
  font-size: 0.7rem;
  margin-left: 0.4rem;
`
