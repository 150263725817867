import { LinkPlaceNav, LogoPlaceNav, NavBarLeftPlace } from './SideBarContent.styled'
import Logo from '../../assets/images/Logo.png'
import { Link, NavLink } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
interface SideBarNavContentProps {
  onClose: any
}
export const SideBarNavContnent = ({}: SideBarNavContentProps) => {
  const { logout } = useAuth()
  const cName = 'menu_item ? "active" : ""}'
  return (
    <>
      <NavBarLeftPlace>
        <LogoPlaceNav>
          <Link to='/'>
            <img src={Logo} alt='logo' />
          </Link>
        </LogoPlaceNav>
        <LinkPlaceNav>
          <NavLink to='/'>Dashboard</NavLink>
          {/* <NavLink to='/licences'>Licences</NavLink> */}
          {/* <NavLink to='/certificates'>Certificates</NavLink> */}
          <NavLink to='/employees'>Employees</NavLink>
          {/* <NavLink to='/jobs'>Jobs</NavLink> */}
          <NavLink className={cName} to='/logout' onClick={() => logout()}>
            Logout
          </NavLink>
        </LinkPlaceNav>
      </NavBarLeftPlace>
    </>
  )
}
