import React, { useState } from 'react'
import { ClipBoard } from '../../assets/icons/clipboard'
import { LicensesTitle } from '../../components/LicensesTitle/LicensesTitle'
import { NavBar } from '../../components/NavBar/NavBar'
import { Flex, H1, IconButton, P1, P2, PageFooter, Separator } from '../../styling/GlobalStyles'
import {
  ButtonActiv,
  ButtonExpired,
  DashboardWrapper,
  LicencesCards,
  LicencesOneCard,
} from './Licenses.styled'
import { Pagination } from '../../components/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'

export const Licenses = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1)
  return (
    <>
      <DashboardWrapper>
        {/* <NavBar /> */}
        <LicensesTitle />
        <LicencesCards>
          <LicencesOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <ClipBoard></ClipBoard>
              </IconButton>
              <Flex column>
                <P1 bold>Anual Minerals </P1>
                <P1 bold>Rights Fee</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex between>
              <H1 orange>GH₵ 8,000</H1>
              {/* <Button>Active</Button> */}
              <ButtonActiv>Active</ButtonActiv>
            </Flex>
            <Separator />
            <Flex column gap='0.6rem'>
              <P2>Open Balance: GHC 4.000</P2>
              <P2 green>Paid: GHC 4.000</P2>
              <P2>Expires: 4. June 2023.</P2>
            </Flex>
          </LicencesOneCard>
          <LicencesOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <ClipBoard></ClipBoard>
              </IconButton>
              <Flex column>
                <P1 bold>Anual Minerals </P1>
                <P1 bold>Rights Fee</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex between>
              <H1 orange>GH₵ 8,000</H1>
              {/* <Button greenChart>Active</Button> */}
              <ButtonExpired>Expired</ButtonExpired>
            </Flex>
            <Separator />
            <Flex column gap='0.6rem'>
              <P2>Open Balance: GHC 4.000</P2>
              <P2 green>Paid: GHC 4.000</P2>
              <P2>Expires: 4. June 2023.</P2>
            </Flex>
          </LicencesOneCard>
          <LicencesOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <ClipBoard></ClipBoard>
              </IconButton>
              <Flex column>
                <P1 bold>Anual Minerals </P1>
                <P1 bold>Rights Fee</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex between>
              <H1 orange>GH₵ 8,000</H1>
              {/* <Button>Active</Button> */}
              <ButtonActiv>Active</ButtonActiv>
            </Flex>
            <Separator />
            <Flex column gap='0.6rem'>
              <P2>Open Balance: GHC 4.000</P2>
              <P2 green>Paid: GHC 4.000</P2>
              <P2>Expires: 4. June 2023.</P2>
            </Flex>
          </LicencesOneCard>
          <LicencesOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <ClipBoard></ClipBoard>
              </IconButton>
              <Flex column>
                <P1 bold>Anual Minerals </P1>
                <P1 bold>Rights Fee</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex between>
              <H1 orange>GH₵ 8,000</H1>
              {/* <Button className="btnWidth">Active</Button> */}
              <ButtonActiv>Active</ButtonActiv>
            </Flex>
            <Separator />
            <Flex column gap='0.6rem'>
              <P2>Open Balance: GHC 4.000</P2>
              <P2 green>Paid: GHC 4.000</P2>
              <P2>Expires: 4. June 2023.</P2>
            </Flex>
          </LicencesOneCard>
        </LicencesCards>

        <PageFooter>
          {/* {Math.ceil(operations.totalCount / 30) > 1 && ( */}
          <Pagination
            setPage={(selected) => navigate('?page=' + selected + '&limit=30')}
            // pageCount={Math.ceil(operations.totalCount / 30)}
            pageCount={5}
            forcePage={page - 1}
          />
          {/* )} */}
        </PageFooter>
      </DashboardWrapper>
    </>
  )
}
