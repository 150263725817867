import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { WaterBodiesTitle } from '../../components/WaterBodiesTitle/WaterBodiesTitle'
import {
  WaterodiesContainer,
  WatherBodiedsImgStatus,
  WatherBodiedsImgText,
  WatherBodiedsImgTitle,
  WatherBodiedsMain,
  WatherBodiedsMainImgPlace,
  WatherBodiedsMainPlace,
  WatherBodiedsMainTitle,
} from './WaterBodies.styled'
import { SliderSwiper } from '../../components/SwiperSlider/SliderSwiper'
import { Flex, H1, H2, P1, P2, Separator } from '../../styling/GlobalStyles'
import { useQuery } from '@apollo/client'
import { GET_COMPANY_BY_ID, GET_WATER_BODIES_BY_COMPANY_ID } from '../../graphql'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedCompany } from 'features/companies/companiesSlice'
import { Spinner } from 'components/Loading/Loading'
import { Emptylist } from 'components/EmptyList/Emptylist'

export const WaterBodies = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { selectedCompany } = useSelector((state: RootState) => state.companies)
  const companyById = useQuery(GET_COMPANY_BY_ID, { skip: true })

  const waterBodies = useQuery(GET_WATER_BODIES_BY_COMPANY_ID, {
    variables: { companyId: selectedCompany.id },
  })

  const handleCompanyChange = async (company) => {
    const comp = await companyById.refetch({ companyId: company.id })
    dispatch(setSelectedCompany(comp.data.getCompanyById))
  }
  console.log(waterBodies.data)
  return (
    <>
      <WaterodiesContainer>
        <NavBar handleCompanyChange={handleCompanyChange} />
        <WaterBodiesTitle />
        <Separator margin='1.5rem 0 2.5rem 0' />
        {waterBodies?.data?.listWaterBodiesByCompanyId?.length > 0 ? (
          waterBodies?.data?.listWaterBodiesByCompanyId?.map((l, k) => (
            <WatherBodiedsMainPlace key={k}>
              <WatherBodiedsMain>
                {/* <Flex between center mb='16'>
                <Flex>
                  <H1>{l.title}</H1>
                </Flex>
              </Flex> */}
                <WatherBodiedsMainTitle>
                  <H1>{l.title}</H1>
                </WatherBodiedsMainTitle>
                <WatherBodiedsMainImgPlace>
                  <SliderSwiper
                    slides={l?.images?.map((i) => ({
                      image: i.image,
                      date: new Date(),
                      // imageHeadline: i.caption,
                    }))}
                  />
                  <WatherBodiedsImgTitle>
                    <H2>{l.title}</H2>
                  </WatherBodiedsImgTitle>
                  <WatherBodiedsImgText>
                    <P2>
                      Latitude : {l?.coordinates.lat} I Longitude : {l?.coordinates.lng}
                    </P2>
                  </WatherBodiedsImgText>
                  <WatherBodiedsImgStatus>
                    {l.status ? (
                      <P1 blue bold>
                        CLEAN
                      </P1>
                    ) : (
                      <P1 red bold>
                        SMOGGY
                      </P1>
                    )}
                  </WatherBodiedsImgStatus>
                </WatherBodiedsMainImgPlace>

                <Separator margin='1.5rem 0 2.5rem 0' />
              </WatherBodiedsMain>
            </WatherBodiedsMainPlace>
          ))
        ) : (
          <Emptylist />
        )}

        <SliderSwiper slides={[]} />
        <Spinner loading={companyById.loading || !selectedCompany.id} />
      </WaterodiesContainer>
    </>
  )
}
