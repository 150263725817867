import React from 'react'

export const AlertTriangle = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.29 3.8602L1.82 18.0002C1.64537 18.3026 1.55296 18.6455 1.55199 18.9947C1.55101 19.3439 1.64149 19.6873 1.81442 19.9907C1.98736 20.2941 2.23672 20.547 2.53771 20.7241C2.83869 20.9012 3.1808 20.9964 3.53 21.0002H20.47C20.8192 20.9964 21.1613 20.9012 21.4623 20.7241C21.7633 20.547 22.0126 20.2941 22.1856 19.9907C22.3585 19.6873 22.449 19.3439 22.448 18.9947C22.447 18.6455 22.3546 18.3026 22.18 18.0002L13.71 3.8602C13.5317 3.56631 13.2807 3.32332 12.9812 3.15469C12.6817 2.98605 12.3437 2.89746 12 2.89746C11.6563 2.89746 11.3183 2.98605 11.0188 3.15469C10.7193 3.32332 10.4683 3.56631 10.29 3.8602V3.8602Z'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 9V13'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 17H12.01'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
