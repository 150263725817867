import styled from 'styled-components'

export const NavBarWrapper = styled.div`
  margin: 0 auto 1.2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.6rem;
  width: 100%;
  text-decoration: none;
  background: #ffffff;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  div > a > img {
    border-right: 0.05rem solid #e7e7e7;
    width: 5.5rem;
    padding-right: 0.62rem;
  }
  @media (max-width: 900px) {
    div > a > img {
      border-right: none;
    }
    /* @media (max-width: 590px) {
      justify-content: center;
    } */
  }
  @media (max-width: 500px) {
    background: #ffffff;
    box-shadow: 0 0 0 0;
    border-radius: 0;
    gap: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div > a > img {
      padding-right: 0px;
      width: 80%;
    }
  }
`
export const LogoLinkPLaceNav = styled.div`
  display: flex;

  gap: 2.5rem;
`
export const NavBarLeftPlace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  padding-left: 1.25rem;

  a {
    text-decoration: none;
    /* color: ${(props) => props.theme.colorGray}; */
    font-weight: 700;
    font-size: 0.87rem;
    line-height: 1.313rem;
    &:hover {
      color: ${(props) => props.theme.colorOrangeHover};
    }
  }
  .menu_item {
    color: ${(props) => props.theme.colorGray};
  }
  .menu_item.active {
    color: ${(props) => props.theme.colorOrangeHover};
  }
  @media (max-width: 500px) {
    gap: 8px;
    padding-left: 0;
    order: 2;
  }
`
export const LogoPlaceNav = styled.div`
  @media (max-width: 900px) {
    display: flex;
  }
  @media (max-width: 500px) {
    display: none;
  }
`
export const LogoPlaceNavMobile = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    order: -2;
    background: #ffffff;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    width: 62px;
    height: 60px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
      }
    }
  }
`
export const LinkPlaceNav = styled.div`
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    display: none;
    order: -1;
  }
`

export const NavBarRightPlace = styled.div`
  display: flex;
  gap: 0.625rem;
  padding-right: 1.25rem;

  /* width: 100%; */
  justify-content: end;
  @media (max-width: 500px) {
    justify-content: start;
    padding-right: 0;
  }
`
export const NavBarUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  p {
    margin: 0px;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.3rem;
    color: ${(props) => props.theme.colorGray};
  }
  span {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1rem;
    text-align: right;
    letter-spacing: 0.021rem;
    color: ${(props) => props.theme.colorGray};
  }
  @media (max-width: 900px) {
    display: none;
  }
`

export const NavBarUserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.9rem;
  flex-direction: column;
  position: relative;

  ul {
    margin: 0;
    padding: 0;
    position: absolute;
    top: calc(100% + 5px);
    width: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    box-shadow: 0 0 5px #ff9900;
    border: 1px solid #ff9900;
    border-radius: 0.625rem;
    overflow: auto;
    list-style: none;
    z-index: 21;
    background-color: #fff;
  }

  ul a {
    background-color: #fcfcfc;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color ease 0.2s;
    text-decoration: none;
    color: black;
    font-size: 16px;
    width: 100%;
  }
  ul a:hover {
    background-color: #ff9900;
    color: #fcfcfc;
  }
`
export const NavBarSettings = styled.div`
  width: 3.7rem;
  height: 3.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    position: absolute;
    top: calc(100% + 5px);
    width: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    box-shadow: 0 0 5px #ff9900;
    border: 1px solid #ff9900;
    border-radius: 0.625rem;
    overflow: auto;
    list-style: none;
    z-index: 21;
    background-color: #fff;
  }

  ul a {
    background-color: #fcfcfc;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color ease 0.2s;
    text-decoration: none;
    color: black;
    font-size: 16px;
    width: 100%;
  }
  ul a:hover {
    background-color: #ff9900;
    color: #fcfcfc;
  }
  @media (max-width: 500px) {
    display: none;
  }
`
export const NavBarHamburger = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: flex;
    order: -1;
    padding-left: 1rem;
  }

  @media (max-width: 500px) {
    background: #ffffff;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    width: 62px;
    height: 60px;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
`
