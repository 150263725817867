import React, { useRef, useState } from 'react'
import {
  LinkPlaceNav,
  LogoPlaceNav,
  NavBarHamburger,
  NavBarWrapper,
  NavBarLeftPlace,
  NavBarRightPlace,
  NavBarUserInfo,
  NavBarUserIcon,
  NavBarSettings,
  LogoLinkPLaceNav,
  LogoPlaceNavMobile,
} from './NavBar.styled'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../../assets/images/Logo.png'
import Avatar from '../../assets/images/Avatar.png'
// import { Chevron } from "../../assets/images/icons";
import { ProfileImage, ProfilePicture } from '../../styling/GlobalStyles'
import SideSlider from '../SideSlider'
import { Hamburger } from '../../assets/icons/hamburger'
import { HamburgerSettings } from 'assets/icons/hamburgerSettings'
import useAuth from 'hooks/useAuth'
import { useOnClickOutside } from 'utils/helpers'

import { PeriodMine } from '../PeriodMine/PeriodMine'
import { CshoseCompany } from 'components/ChoseCompany/CshoseCompany'

export const NavBar = ({ handleCompanyChange }) => {
  const [open, setOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const handleDropDownFocus = (state: boolean) => {
    setOpen(!state)
  }
  const [navBar, setNavbar] = useState(false)
  const { logout } = useAuth()
  const cName = 'menu_item ? "active" : ""}'
  useOnClickOutside(dropdownRef, () => open && setOpen(false))

  return (
    <>
      <NavBarWrapper>
        <NavBarLeftPlace>
          {/* <LogoLinkPLaceNav> */}
          <LogoPlaceNav>
            <Link to='/'>
              <img src={Logo} alt='logo' />
            </Link>
          </LogoPlaceNav>
          <LinkPlaceNav>
            <NavLink className={cName} to='/'>
              Dashboard
            </NavLink>
            {/* <NavLink className={`menu_item ? 'active' : ''}`} to="/licences">
              Licences
            </NavLink> */}
            {/* <NavLink className={`menu_item ? 'active' : ''}`} to="/certificates">
              Certificates
            </NavLink> */}
            <NavLink className={cName} to='/employees'>
              Employees
            </NavLink>
            {/* <NavLink className={'menu_item ? \'active\' : \'\'}'} to='/jobs'>
              Jobs
            </NavLink> */}
            {/* <NavLink className={cName} to='/logout' onClick={() => logout()}>
              Logout
            </NavLink> */}
          </LinkPlaceNav>
          {/* </LogoLinkPLaceNav> */}
          <NavBarHamburger onClick={() => setNavbar(!navBar)}>
            <Hamburger />
          </NavBarHamburger>
        </NavBarLeftPlace>

        <LogoPlaceNavMobile>
          <Link to='/'>
            <img src={Logo} alt='logo' />
          </Link>
        </LogoPlaceNavMobile>

        <NavBarRightPlace>
          <CshoseCompany handleCompanyChange={handleCompanyChange} />
          <NavBarSettings tabIndex={1} ref={dropdownRef} onClick={() => handleDropDownFocus(open)}>
            {open && (
              <ul>
                <NavLink className={cName} to='/logout' onClick={() => logout()}>
                  Logout
                </NavLink>
              </ul>
            )}
            <HamburgerSettings />
          </NavBarSettings>
        </NavBarRightPlace>
      </NavBarWrapper>
      <SideSlider open={navBar} onClose={setNavbar} history />
    </>
  )
}
