import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto 1.375rem auto;
  span {
    font-size: 0.62rem;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.02em;
    color: ${(props) => props.theme.colorGray};
    font-weight: normal;
  }
`
