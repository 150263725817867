import React, { useState } from 'react'
import { DashboardContainer, DashboardContainerLeft, EmployeesNumber } from './JobsTitle.styled'
import { BigPageTitle, P2, Tabs } from '../../styling/GlobalStyles'
export const JobsTitle = () => {
  const [activeTab, setActiveTav] = useState(1)
  return (
    <>
      <DashboardContainer>
        <DashboardContainerLeft>
          <BigPageTitle>Jobs</BigPageTitle>
        </DashboardContainerLeft>
        <EmployeesNumber>
          <Tabs width='200px' active={activeTab === 1} onClick={() => setActiveTav(1)}>
            <P2>Add New</P2>
          </Tabs>
        </EmployeesNumber>
      </DashboardContainer>
    </>
  )
}
