import React from 'react'

export const Line = () => {
  return (
    <div>
      <svg width='1' height='60' viewBox='0 0 1 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <line x1='0.25' y1='1.09278e-08' x2='0.249997' y2='60' stroke='#DFDEDE' strokeWidth='0.5' />
      </svg>
    </div>
  )
}
