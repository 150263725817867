import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

export const JobsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
  gap: 4.375rem;
  @media (max-width: 1030px) {
    flex-wrap: wrap;
  }
  @media (max-width: 640px) {
    margin-top: 0rem;
  }
`
export const JobsCard = styled.div`
  width: 23.75rem;
  /* height: 154px; */
  gap: 1rem;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0px 0px 22.6253px rgba(0, 0, 0, 0.15);
  border-radius: 10.5585px;
  padding: 1.1rem;
  display: flex;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-0.3rem);
  }
  @media (max-width: 640px) {
    width: 100%;
    justify-content: space-between;
  }
`
export const CardPicture = styled.div`
  width: 144px;
  height: 120px;
  background-color: black;
  border-radius: 0.46rem;
`
