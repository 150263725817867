import styled from 'styled-components'
import backgroundImg from '../../assets/images/login-background.png'

export const LoginWrapper = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
      89.59deg,
      #ff990095 0.92%,
      rgba(255, 197, 109, 0) 57.34%,
      rgba(255, 255, 255, 0) 99.64%
    ),
    url(${backgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const LoginContainer = styled.div`
  position: relative;
  width: 26.2rem;
  padding: 0 2.3rem;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    width: 20rem;
  }
`
export const LoginDivider = styled.div`
  padding-left: 10vw;
  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
`

export const CodeGoBack = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
  /* svg {
    width: 30px;
    height: 30px;
  } */
`
export const PhoneNumberError = styled.div`
  position: absolute;
  font-size: 0.7rem;
  color: red;
`
