import styled from 'styled-components'

export const SelectPeriodMine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 3.7rem;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  position: relative;
  @media (max-width: 590px) {
    width: 200px;
  }
  @media (max-width: 500px) {
    height: 60px;
  }
  button {
    padding: 15px 20px;
    box-shadow: 0 0 25px -20px #dbdbdb;
    flex: 1;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    border: 1 solid #919191;
  }
  ul {
    overflow: scroll;
    max-height: 300px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    box-shadow: 0 0 5px #ff9900;
    border: 1px solid #ff9900;
    border-radius: 0.625rem;
    overflow: auto;
    list-style: none;
    z-index: 21;
    background-color: #fff;
  }
  ul li {
    background-color: #fcfcfc;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color ease 0.2s;
  }
  ul li:hover {
    background-color: #ff9900;
    color: #fcfcfc;
  }
`
export const SelectPeriod = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9rem;
    letter-spacing: -0.02em;
    font-feature-settings: 'calt' off;
    color: ${(props) => props.theme.colorGray};
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.3rem;
    color: ${(props) => props.theme.colorGray};
    overflow: hidden;
    white-space: nowrap;
    width: 8rem;
    text-overflow: ellipsis;
  }
`
export const DrowDownCompany = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
