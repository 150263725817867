import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`
export const CertificateCards = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1.37rem;
  gap: 1rem;
  justify-content: space-between;
  @media (max-width: 1110px) {
    flex-wrap: wrap;
  }
  @media (max-width: 515px) {
    flex-direction: column;
    width: 100%;
  }
`
export const CertificateOneCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  padding: 1.375rem;
  width: 25%;
  @media (max-width: 1110px) {
    width: calc(50% - 1rem);
  }
  @media (max-width: 515px) {
    width: 100%;
  }
`
