import styled from 'styled-components'
export const DashboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`
export const LicencesCards = styled.div`
  display: flex;
  width: 100%;
  /* margin-top: 1.5rem; */
  /* padding-top: 1.37rem; */
  padding-bottom: 1.37rem;
  gap: 1rem;
  justify-content: space-between;
  @media (max-width: 1110px) {
    flex-wrap: wrap;
  }
`
export const LicencesOneCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colorWhite};
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.875rem;
  padding: 1.375rem;
  width: 25%;
  @media (max-width: 1110px) {
    width: calc(50% - 1rem);
  }
  @media (max-width: 515px) {
    width: 100%;
  }
`
export const ButtonActiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 6.6rem;
  height: 2.4rem;
  padding: 0.6rem 0.45rem;
  border-radius: 0.625rem;
  background-color: #00b04c;
  color: white;
  border: none;
`
export const ButtonExpired = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 6.6rem;
  height: 2.4rem;
  padding: 0.6rem 0.45rem;
  border-radius: 0.625rem;
  background-color: #ff4f46;
  color: white;
  border: none;
`
