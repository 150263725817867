import React from 'react'

export const HamburgerSettings = () => {
  return (
    <>
      <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.5 10.1626C2.5 9.81742 2.77982 9.5376 3.125 9.5376H16.875C17.2202 9.5376 17.5 9.81742 17.5 10.1626C17.5 10.5078 17.2202 10.7876 16.875 10.7876H3.125C2.77982 10.7876 2.5 10.5078 2.5 10.1626Z'
          fill='#464255'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.5 5.1626C2.5 4.81742 2.77982 4.5376 3.125 4.5376H16.875C17.2202 4.5376 17.5 4.81742 17.5 5.1626C17.5 5.50778 17.2202 5.7876 16.875 5.7876H3.125C2.77982 5.7876 2.5 5.50778 2.5 5.1626Z'
          fill='#464255'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.5 15.1626C2.5 14.8174 2.77982 14.5376 3.125 14.5376H16.875C17.2202 14.5376 17.5 14.8174 17.5 15.1626C17.5 15.5078 17.2202 15.7876 16.875 15.7876H3.125C2.77982 15.7876 2.5 15.5078 2.5 15.1626Z'
          fill='#464255'
        />
      </svg>
    </>
  )
}
