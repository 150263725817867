import { useQuery } from '@apollo/client'
import { GET_COMPANY_BY_ID, LIST_COMPANIES } from '../graphql'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { setSelectedCompany } from 'features/companies/companiesSlice'

const useFirstCompany = (dateFrom,dateTo) => {
  const { selectedCompany } = useSelector((state: RootState) => state.companies)
  const dispatch = useDispatch<AppDispatch>()

  const listData = useQuery(LIST_COMPANIES, { skip: selectedCompany?.id ? true : false})
  const { loading, data, refetch } = useQuery(GET_COMPANY_BY_ID, {
    skip: selectedCompany?.id ? true : false,
    variables: {
      dateFrom,
      dateTo,
      companyId: selectedCompany?.id,
    },
  })
  const getFirstCompany = async () => {
    const company = (await listData.refetch({})).data
    await refetch({ companyId: company.listCompanies[0].id })
    data?.getCompanyById && dispatch(setSelectedCompany(data.getCompanyById))
  }
  if (!selectedCompany?.id) {
    getFirstCompany()
  }
  return [loading]
}

export default useFirstCompany
