import { useEffect } from 'react'
import { toast } from 'react-toastify'
// click outside REF
export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
// end of click outside REF

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

// prevent extra char for NUMBER input
export const blockInvalidChar = (e) => ['e', 'E', '-'].includes(e.key) && e.preventDefault()
export const onlyNumbers = (e) => ['e', 'E', '-', '+'].includes(e.key) && e.preventDefault()
export const onlyLetters = (e) => {
  const regex = /^[A-Za-z\s]*$/
  const valid = regex.test(e.key)

  if (!valid) e.preventDefault()
}

export const blockAllInvalidChar = (e) => {
  const reg = /^([0-9]{1,})[.]([0-9]{2})$/
  // /^[1-9]\d*(\.\d+)?$/
  reg.test(e.key) && e.preventDefault()
}

// end of prevent extra char for NUMBER input

export const handleOptionBtn = (item, setEditState, setMenuState, editID) => {
  setEditState(item.id)
  if (editID === item.id) {
    setMenuState((prev) => !prev)
    return
  }
  setMenuState(true)
}

export const convertMinsToHrsMins = (mins, shiftStart) => {
  if (shiftStart) {
    if (shiftStart + mins > 1440) {
      mins = mins - (1440 - shiftStart)
    } else {
      mins = shiftStart + mins
    }
  }

  let h = Math.floor(mins / 60)
  let m = mins % 60
  h = h < 10 ? '0' + h : h // (or alternatively) h = String(h).padStart(2, '0')
  m = m < 10 ? '0' + m : m // (or alternatively) m = String(m).padStart(2, '0')
  return `${h}:${m}`
}

export const addZero = (num) => {
  if (parseInt(num) <= 9) {
    return '0' + num.toString()
  }
  return num
}

export const convertToDate = (param) => {
  let date = new Date(param)

  let day = date.getDate(param)
  let month = date.getMonth(param) + 1
  let year = date.getFullYear(param)

  return `${year}-${addZero(month)}-${addZero(day)}`
}
export const sortList = (data, param) => {
  let newArray = data && [...data]
  newArray.sort((a, b) => a[param].localeCompare(b[param]))
  return newArray
}
export const sortNestedList = (data, param1, param2) => {
  let newArray = data && [...data]
  newArray.sort((a, b) => a[param1][param2].localeCompare(b[param1][param2]))
  return newArray
}

export const convertFuelType = (type) => {
  switch (type) {
    case 1:
      return 'Petrol'
    case 2:
      return 'Diesel'
    case 3:
      return 'Electricity'
  }
}

export const isEmpty = (obj) => Object.keys(obj).length === 0

export const formatDate = (value) => {
  const date = new Date(value.setDate(value.getDate() + 1)).toISOString()
  return date
}
export const convertAbsence = (type) => {
  switch (type) {
    case 1:
      return 'Day Off'
    case 2:
      return 'Vacation'
    case 3:
      return 'Maternity Leave'
    case 4:
      return 'Sick'
  }
}

export const removeDuplicates = (arr, id) => {
  let uniq = {}
  return !id
    ? arr.filter((obj) => !uniq[obj.value] && (uniq[obj.value] = true))
    : arr.filter((obj) => !uniq[obj.id] && (uniq[obj.id] = true))
}

export const handleErrors = (payload) => {
  const nestedMsg = payload.response.data.message

  if (Array.isArray(nestedMsg)) {
    return nestedMsg.map((i) => toast.error(i))
  }
  toast.error(nestedMsg)
}

export const handleQuerySubmit = (e, setParams, limit) => {
  e.preventDefault()
  setParams((prev) => {
    const copy = { ...prev }
    copy.q = e.target[1].value
    copy.page = 1
    copy.limit = limit ? limit : 30
    return copy
  })
}

export const handlePagination = (page, setParams, limit) => {
  setParams((prev) => {
    const copy = { ...prev }
    copy.page = page
    copy.limit = limit ? limit : 30
    return copy
  })
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getCenter = (area) => {
  const latitudes = area.map((it) => it.lat)
  const longitudes = area.map((it) => it.lng)
  const center = {
    ...area,
    lat: (Math.min(...latitudes) + Math.max(...latitudes)) / 2,
    lng: (Math.min(...longitudes) + Math.max(...longitudes)) / 2,
  }

  return center
}