import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Line } from '../../assets/icons/line'
import { ArrowLeft } from '../../assets/icons/arrowLeft'
import { Flex, H1, IconButtonNeutral, P2 } from '../../styling/GlobalStyles'
import { DashboardContainer } from './ManagementTitle.styled'

export const ManagementTitle = () => {
  const navigate = useNavigate()
  return (
    <>
      <DashboardContainer>
        <Flex between width='100%'>
          <Flex gap='1.2rem' center justcenter>
            <IconButtonNeutral onClick={() => navigate(-1)}>
              <ArrowLeft />
            </IconButtonNeutral>
            <Line />
            <Flex column justcenter>
              <H1 orange>Management</H1>
              <P2>BSD Mining Service for Ghana</P2>
            </Flex>
          </Flex>
          <Flex alend>
            <span>Number of Employees: 23</span>
          </Flex>
        </Flex>
      </DashboardContainer>
    </>
  )
}
