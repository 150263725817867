import styled from 'styled-components'

export const GallerySliders = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  position: relative;
`
export const GallerySlider = styled.div`
  display: flex;
  width: 100%;
  background: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  height: 239px;
  cursor: grab;
  /* max-width: 320px; */
`
export const ImgPLaceContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  /* gap: 32px; */
  min-width: 323px;
`
export const TimePicture = styled.div`
  padding: 0.3rem;
  background-color: ${(props) => props.theme.colorWhite};
  position: absolute;
  top: 0.93rem;
  right: 2.6rem;
  border-radius: 0.5rem;
  color: ${(props) => props.theme.colorGray};
`
export const SwiperWrapper = styled.div`
  width: 100%;
  .swiper-button-prev,
  .swiper-button-next {
    top: 45%;
    width: 40px;
    height: 40px;
    background: #fff;
    border: none;
    border-radius: 50%;
    color: #ff9900;
    font-weight: 700;
    outline: 0;
    transition: background-color 0.2s ease, color 0.2s ease;

    &::after {
      font-size: 16px;
    }
  }

  .swiper-button-prev {
    &:after {
      position: relative;
      left: -1px;
    }
  }

  .swiper-button-next {
    &:after {
      position: relative;
      left: 1px;
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
`
