import React from 'react'
import { RightPlaceDashboard, EnvironmentalItems } from './RightSideDashboard.styled'
import { ArrowRight } from '../../assets/icons/ArrowRight'
// import { MapPin } from '../../assets/icons/mapPin'

import {
  BigPageTitle,
  H2,
  H1,
  IconButton,
  IconButtonNeutral,
  Flex,
  theme,
  P1,
} from '../../styling/GlobalStyles'
import { Book } from '../../assets/icons/book'
import { Heart } from '../../assets/icons/heart'
import { Home } from '../../assets/icons/home'
import { UserPlus } from '../../assets/icons/userPlus'
import { Droplet } from '../../assets/icons/droplet'
import { useNavigate } from 'react-router-dom'
import { IRightSideDashboard } from 'types/components.interface'

export const RightSideDashboard = ({ company, dateFrom, dateTo }: IRightSideDashboard) => {
  const navigate = useNavigate()

  const getAllocation = (name: string) => {
    return company.csrAllocationSum?.find(a => a.type == name)?.investmentAmount || 0
  }

  return (
    <>
      <RightPlaceDashboard>
        <EnvironmentalItems>
          <Flex column>
            <H2>Royality Earned</H2>
            <BigPageTitle>No Data</BigPageTitle>
          </Flex>
          <IconButton>
            <ArrowRight />
          </IconButton>
        </EnvironmentalItems>
        <Flex>
          <H1>CSR Allocation</H1>
        </Flex>
        <EnvironmentalItems onClick={() => navigate('/education?' + new URLSearchParams({ dateFrom, dateTo }).toString())}>
          <Flex gap='1.06rem' center>
            <Flex>
              <IconButton noneHoverColor={theme.colorGray}>
                <Book />
              </IconButton>
            </Flex>
            <Flex column>
              <P1 bold>Education</P1>
              <H1 color={theme.colorOrange}>GH₵ {getAllocation('education')}</H1>
            </Flex>
          </Flex>
          <IconButtonNeutral>
            <ArrowRight />
          </IconButtonNeutral>
        </EnvironmentalItems>
        <EnvironmentalItems onClick={() => navigate('/medical?' + new URLSearchParams({ dateFrom, dateTo }).toString())}>
          <Flex gap='1.06rem' center>
            <Flex>
              <IconButton noneHoverColor={theme.colorGray}>
                <Heart />
              </IconButton>
            </Flex>
            <Flex column>
              <P1 bold>Medical</P1>
              <H1 color={theme.colorOrange}>GH₵ {getAllocation('medical')}</H1>
            </Flex>
          </Flex>
          <IconButtonNeutral>
            <ArrowRight />
          </IconButtonNeutral>
        </EnvironmentalItems>
        <EnvironmentalItems onClick={() => navigate('/infrastructure?' + new URLSearchParams({ dateFrom, dateTo }).toString())}>
          <Flex gap='1.06rem' center>
            <Flex>
              <IconButton noneHoverColor={theme.colorGray}>
                <Home />
              </IconButton>
            </Flex>
            <Flex column>
              <P1 bold>Infrastructure</P1>
              <H1 color={theme.colorOrange}>GH₵ {getAllocation('infrastructure')}</H1>
            </Flex>
          </Flex>
          <IconButtonNeutral>
            <ArrowRight />
          </IconButtonNeutral>
        </EnvironmentalItems>
        <EnvironmentalItems onClick={() => navigate('/community?' + new URLSearchParams({ dateFrom, dateTo }).toString())}>
          <Flex gap='1.06rem' center>
            <Flex>
              <IconButton noneHoverColor={theme.colorGray}>
                <UserPlus />
              </IconButton>
            </Flex>
            <Flex column>
              <P1 bold>Community</P1>
              <H1 color={theme.colorOrange}>GH₵ {getAllocation('community')}</H1>
            </Flex>
          </Flex>
          <IconButtonNeutral>
            <ArrowRight />
          </IconButtonNeutral>
        </EnvironmentalItems>
        <EnvironmentalItems onClick={() => navigate('/water?' + new URLSearchParams({ dateFrom, dateTo }).toString())}>
          <Flex gap='1.06rem' center>
            <Flex>
              <IconButton noneHoverColor={theme.colorGray}>
                <Droplet />
              </IconButton>
            </Flex>
            <Flex column>
              <P1 bold>Water</P1>
              <H1 color={theme.colorOrange}>GH₵ {getAllocation('water')}</H1>
            </Flex>
          </Flex>
          <IconButtonNeutral>
            <ArrowRight />
          </IconButtonNeutral>
        </EnvironmentalItems>
      </RightPlaceDashboard>
    </>
  )
}
