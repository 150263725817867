import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Award } from '../../assets/icons/award'
import { CertificateTitle } from '../../components/CertificateTitle/CertificateTitle'
import { NavBar } from '../../components/NavBar/NavBar'
import { Pagination } from '../../components/Pagination/Pagination'
import { Flex, P1, P2, IconButton, Separator, PageFooter } from '../../styling/GlobalStyles'
import { CertificateCards, CertificateOneCard, DashboardWrapper } from './Certificate.styled'

export const Certificate = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  return (
    <>
      <DashboardWrapper>
        {/* <NavBar /> */}
        <CertificateTitle />
        <CertificateCards>
          <CertificateOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <Award></Award>
              </IconButton>
              <Flex column>
                <P1 bold>Mining Competence</P1>
                <P1 bold>Certificate</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>2</P2>
              <P2>Blasting</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>1</P2>
              <P2>Mine Foreman - Engineer</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>5</P2>
              <P2>Mine Captain</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>8</P2>
              <P2>Mine Superintendent</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>1</P2>
              <P2>Mine Manager</P2>
            </Flex>
            <Separator />
          </CertificateOneCard>

          {/* 2 */}
          <CertificateOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <Award></Award>
              </IconButton>
              <Flex column>
                <P1 bold>EHS Competence</P1>
                <P1 bold>Certificate</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>2</P2>
              <P2>EHS Officer</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>1</P2>
              <P2>EHS Senior Officer</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>5</P2>
              <P2>EHS Superintendent</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>8</P2>
              <P2>EHS Manager</P2>
            </Flex>
            <Separator />
          </CertificateOneCard>
          {/* 3 */}
          <CertificateOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <Award></Award>
              </IconButton>
              <Flex column>
                <P1 bold>Processing Competence</P1>
                <P1 bold>Certificate</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>2</P2>
              <P2>Processing Engineer</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>1</P2>
              <P2>Senior Processing Engineer</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>5</P2>
              <P2>Processing Superintendent</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>8</P2>
              <P2>Processing Manager</P2>
            </Flex>
            <Separator />
          </CertificateOneCard>
          {/* 4 */}
          <CertificateOneCard>
            <Flex center gap='1.125rem'>
              <IconButton>
                <Award></Award>
              </IconButton>
              <Flex column>
                <P1 bold>Metallurgical Competence</P1>
                <P1 bold>Certificate</P1>
              </Flex>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>2</P2>
              <P2>Metallurgical Engineer</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>1</P2>
              <P2>Senior Metallurgical Engineer</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>5</P2>
              <P2>Metallurgical Superintendent</P2>
            </Flex>
            <Separator />
            <Flex gap='0.688rem'>
              <P2 orange>8</P2>
              <P2>Metallurgical Manager</P2>
            </Flex>
            <Separator />
          </CertificateOneCard>
        </CertificateCards>

        <PageFooter>
          {/* {Math.ceil(operations.totalCount / 30) > 1 && ( */}
          <Pagination
            setPage={(selected) => navigate('?page=' + selected + '&limit=30')}
            // pageCount={Math.ceil(operations.totalCount / 30)}
            pageCount={5}
            forcePage={page - 1}
          />
          {/* )} */}
        </PageFooter>
      </DashboardWrapper>
    </>
  )
}
