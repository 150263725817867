import React from 'react'

export const BtnTrue = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='48' height='48' rx='8.33483' fill='#0EB01E' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.6031 12.1054C23.849 11.9649 24.151 11.9649 24.3969 12.1054L35.5969 18.5054C35.8462 18.6478 36 18.9129 36 19.2V20.3518C36 27.6037 31.1926 33.977 24.2198 35.9692C24.0761 36.0103 23.9239 36.0103 23.7802 35.9692C16.8074 33.977 12 27.6037 12 20.3518V19.2C12 18.9129 12.1538 18.6478 12.4031 18.5054L23.6031 12.1054ZM23.3149 29.1371L30.2247 20.4998L28.9753 19.5002L23.0852 26.8629L18.9122 23.3854L17.8879 24.6146L23.3149 29.1371Z'
        fill='white'
      />
    </svg>
  )
}
