import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store'
import { Authenticator } from '@aws-amplify/ui-react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'contexts/AuthContext'
import { theme } from './styling/GlobalStyles'
import AppSyncClient from './services/AppSync'
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Suspense fallback='Loading...'>
    <ApolloProvider client={AppSyncClient}>
      <Provider store={store}>
        <Authenticator.Provider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ThemeProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </Provider>
    </ApolloProvider>
  </Suspense>,
)
