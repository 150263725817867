import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { SettingsTitle } from '../../components/SettingsTitle/SettingsTitle'
import {
  CompanyOptions,
  CompanyOptionsCard,
  CompanyOptionsPlace,
  GalleryAddPicture,
} from '../../components/SettingsTitle/SettingsTitle.styled'
import { P2 } from '../../styling/GlobalStyles'
import { DashboardWrapper } from './Settings.styled'
import { GalleryAdd } from '../../assets/icons/galleryAdd'

export const Settings = () => {
  return (
    <>
      <DashboardWrapper>
        {/* <NavBar /> */}
        <SettingsTitle />
        <GalleryAddPicture>
          <GalleryAdd />
        </GalleryAddPicture>
        <CompanyOptionsPlace>
          <CompanyOptions>
            <CompanyOptionsCard>
              <P2 bold>COMPANY NAME</P2>
            </CompanyOptionsCard>
            <CompanyOptionsCard>
              <P2 bold>COMPANY NAME</P2>
            </CompanyOptionsCard>
            <CompanyOptionsCard>
              <P2 bold>COMPANY NAME</P2>
            </CompanyOptionsCard>
            <CompanyOptionsCard>
              <P2 bold>COMPANY NAME</P2>
            </CompanyOptionsCard>
            <CompanyOptionsCard>
              <P2 bold>COMPANY NAME</P2>
            </CompanyOptionsCard>
          </CompanyOptions>
          <CompanyOptions>
            <CompanyOptionsCard>
              <P2 bold>MINE TYPE</P2>
            </CompanyOptionsCard>
          </CompanyOptions>
        </CompanyOptionsPlace>
      </DashboardWrapper>
    </>
  )
}
