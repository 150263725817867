export const FolderNotchOpen = () => (
  <>
    <svg
      width='70'
      height='70'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2056 20.5806C12.3777 19.4085 13.9674 18.75 15.625 18.75H36.4582C37.8012 18.7555 39.1073 19.1909 40.185 19.9923L40.1924 19.9978L51.0444 28.1173C51.0441 28.1171 51.0446 28.1175 51.0444 28.1173C51.0506 28.1217 51.0587 28.1247 51.0663 28.125H78.125C79.7826 28.125 81.3723 28.7835 82.5444 29.9556C83.7165 31.1277 84.375 32.7174 84.375 34.375V43.75C84.375 45.4759 82.9759 46.875 81.25 46.875C79.5241 46.875 78.125 45.4759 78.125 43.75V34.375H51.0418C49.6988 34.3695 48.3927 33.9341 47.315 33.1327L47.3076 33.1272L36.4556 25.0077C36.4554 25.0075 36.4559 25.0079 36.4556 25.0077C36.4494 25.0033 36.4413 25.0003 36.4338 25L15.625 25L15.625 81.25C15.625 82.9759 14.2259 84.375 12.5 84.375C10.7741 84.375 9.375 82.9759 9.375 81.25V25C9.375 23.3424 10.0335 21.7527 11.2056 20.5806Z'
        fill='#464255'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57.1875 40.625H89.4138C89.4137 40.625 89.4139 40.625 89.4138 40.625C90.402 40.625 91.3764 40.8592 92.2565 41.3086C93.1367 41.7581 93.8978 42.4098 94.4773 43.2104C95.0567 44.011 95.4381 44.9376 95.59 45.9141C95.742 46.8907 95.6602 47.8893 95.3513 48.8281L95.3475 48.8398L84.2146 82.2382C83.7893 83.5143 82.5951 84.375 81.25 84.375H12.5C11.4631 84.375 10.4936 83.8606 9.9123 83.002C9.33096 82.1433 9.21341 81.0522 9.59853 80.0894L21.3119 50.806C21.3129 50.8034 21.314 50.8008 21.315 50.7982C21.7755 49.6359 22.576 48.6396 23.612 47.9396C24.6471 47.2402 25.8689 46.8693 27.1178 46.875C27.1218 46.875 27.1258 46.875 27.1297 46.8751L27.1094 50V46.875H27.1178H45.9101C45.9082 46.8751 45.9062 46.8752 45.9042 46.8752L45.9375 50V46.9269L47.6563 49.4922L45.986 46.851C45.9818 46.8536 45.9775 46.8561 45.9732 46.8583L53.7293 41.6617C53.7522 41.6463 53.7753 41.6313 53.7985 41.6166C54.8034 40.9812 55.9653 40.6379 57.1542 40.6252L57.1875 40.625ZM45.9375 46.8822V46.875H45.9101C45.9321 46.8739 45.9536 46.8682 45.9732 46.8583L45.9375 46.8822ZM57.2149 46.875C57.1929 46.8761 57.1714 46.8818 57.1519 46.8917L49.3957 52.0883C49.3728 52.1037 49.3498 52.1187 49.3265 52.1334C48.3216 52.7688 47.1597 53.1121 45.9709 53.1248L45.9375 53.1252L27.1157 53.125L17.1157 78.125H78.9976L89.4143 46.875H57.2149Z'
        fill='#464255'
      />
    </svg>
  </>
)
