import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { JobsTitle } from '../../components/JobsTitle/JobsTitle'
import { DashboardWrapper } from '../Employees/Employees.styled'
import { CardPicture, JobsCard, JobsContainer } from './Jobs.styled'
import { Flex, H2, P3 } from '../../styling/GlobalStyles'
export const Jobs = () => {
  return (
    <>
      <DashboardWrapper>
        {/* <NavBar /> */}
        <JobsTitle />
        <JobsContainer>
          <JobsCard>
            <CardPicture></CardPicture>

            <Flex column between>
              <Flex>
                <H2>EXCAVATOR OPERATOR</H2>
              </Flex>
              <Flex>
                <P3>BSD Mining Services</P3>
              </Flex>
            </Flex>
          </JobsCard>

          <JobsCard>
            <CardPicture></CardPicture>

            <Flex column between>
              <Flex>
                <H2>ELECTRICAN</H2>
              </Flex>
              <Flex>
                <P3>BSD Mining Services</P3>
              </Flex>
            </Flex>
          </JobsCard>
          <JobsCard>
            <CardPicture></CardPicture>

            <Flex column between>
              <Flex>
                <H2>MECHANIC</H2>
              </Flex>
              <Flex>
                <P3>BSD Mining Services</P3>
              </Flex>
            </Flex>
          </JobsCard>
        </JobsContainer>
      </DashboardWrapper>
    </>
  )
}
