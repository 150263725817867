export const Droplet = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 2.69043L17.66 8.35043C18.7794 9.46904 19.5418 10.8945 19.8509 12.4465C20.16 13.9985 20.0019 15.6073 19.3965 17.0694C18.7912 18.5315 17.7658 19.7812 16.4501 20.6605C15.1344 21.5398 13.5875 22.0091 12.005 22.0091C10.4225 22.0091 8.87561 21.5398 7.5599 20.6605C6.24419 19.7812 5.21882 18.5315 4.61347 17.0694C4.00812 15.6073 3.85 13.9985 4.15911 12.4465C4.46822 10.8945 5.23066 9.46904 6.35001 8.35043L12 2.69043Z'
      // stroke="#464255"
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
