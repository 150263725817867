import React, { useRef, useState } from 'react'
import { CloseIcon } from '../../assets/icons/closeIcon'
import { Flex, P1 } from '../../styling/GlobalStyles'
import { ModalContainer } from './ModalPicture.styled'

interface ModalPictureProps {
  close: any
  image: any
}

export const ModalPicture = ({ close, image }: ModalPictureProps) => {
  return (
    <>
      <ModalContainer>
        <Flex column gap='1rem'>
          <Flex width='100%' between>
            <P1 bold>Image Headline</P1>
            {/* <P1 onClick={() => close((prev) => ({ ...prev, open: false }))}>x</P1> */}
            <div
              className='clickBtn'
              onClick={() => close((prev: any) => ({ ...prev, open: false }))}
            >
              <CloseIcon />
            </div>
          </Flex>
          <Flex width='100%' maxHeight='441px'>
            <img style={{ width: '100%', borderRadius: '0.94rem' }} src={image} alt='' />
          </Flex>
        </Flex>
      </ModalContainer>
    </>
  )
}
