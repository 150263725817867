import React from 'react'

export const Hamburger = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 10C2.5 9.65482 2.77982 9.375 3.125 9.375H16.875C17.2202 9.375 17.5 9.65482 17.5 10C17.5 10.3452 17.2202 10.625 16.875 10.625H3.125C2.77982 10.625 2.5 10.3452 2.5 10Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 5C2.5 4.65482 2.77982 4.375 3.125 4.375H16.875C17.2202 4.375 17.5 4.65482 17.5 5C17.5 5.34518 17.2202 5.625 16.875 5.625H3.125C2.77982 5.625 2.5 5.34518 2.5 5Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 15C2.5 14.6548 2.77982 14.375 3.125 14.375H16.875C17.2202 14.375 17.5 14.6548 17.5 15C17.5 15.3452 17.2202 15.625 16.875 15.625H3.125C2.77982 15.625 2.5 15.3452 2.5 15Z'
      fill='#464255'
    />
  </svg>
)
