import { configureStore } from '@reduxjs/toolkit'
import companiesSlice from 'features/companies/companiesSlice'
import globalSlice from 'features/global/globalSlice'

export const store = configureStore({
  reducer: {
    globals: globalSlice,
    companies: companiesSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
